import api from './index';
import Cookies from 'js-cookie';

export const createCustomer = async (formData, token) => {
  try {
    const { accountId, agentId, applicationSource } = formData;

    if (!accountId || !agentId) {
      throw new Error('accountId and agentId are required fields');
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await api.post(
      '/api/customer',
      { accountId, agentId, applicationSource },
      config
    );

    if (response.data.successful) {
      return response.data;
    } else {
      throw new Error(response.data.error?.message || 'Unknown error occurred');
    }
  } catch (error) {
    console.error('Error creating customer:', error.message);
    throw error;
  }
};

export const getCustomerAccount = async () => {
  try {
    const token = Cookies.get('accessToken');

    if (!token) {
      throw new Error('Token not found');
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await api.get('/api/customer', config);

    if (response.data.successful) {
      return response.data.data;
    } else {
      throw new Error(response.data.error.message || 'Unknown error');
    }
  } catch (error) {
    console.error('Error fetching customer account:', error);
    throw error;
  }
};

export const updateCustomerAccount = async (formData) => {
  try {
    const token = Cookies.get('accessToken');

    if (!token) {
      throw new Error('Token not found');
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await api.put('/api/customer', formData, config);

    if (response.data.successful) {
      return response.data.data;
    } else {
      throw new Error(response.data.error.message || 'Unknown error');
    }
  } catch (error) {
    console.error('Error updating customer account:', error);
    throw error;
  }
};

export const fetchPendingCustomers = async (
  pageIndex,
  pageSize,
  token,
  filters,
  sorts
) => {
  try {
    const requestBody = {
      filter: filters,
      pageSize: pageSize,
      pageIndex: pageIndex,
    };

    if (Object.keys(sorts).length > 0) {
      requestBody.sort = sorts;
    }

    const response = await api.post(
      '/api/customer/filter',
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    return {
      successful: false,
      error: {
        message:
          error.response?.data?.error?.message ||
          error.message ||
          'Something went wrong',
      },
    };
  }
};

export const getStatistics = async (token) => {
  try {
    const response = await api.get(`/api/customer/statistics`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.successful) {
      return response.data;
    } else {
      throw new Error(response.data.error?.message || 'Unknown error occurred');
    }
  } catch (error) {
    console.error(
      'Failed to fetch customer data:',
      error.response ? error.response.data : error.message || error
    );
    throw error;
  }
};

export const getCustomerById = async (customerId, token) => {
  try {
    if (!customerId) {
      throw new Error('customerId is required');
    }
    const response = await api.get(`/api/customer/${customerId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.successful) {
      return response.data.data;
    } else {
      throw new Error(response.data.error.message || 'Unknown error occurred');
    }
  } catch (error) {
    console.error(
      'Failed to fetch customer data:',
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const createCustomerByAdmin = async (accountId, customerData, token) => {
  try {
    if (!accountId) {
      console.error('No accountId provided');
      return null;
    }

    const response = await api.post(
      '/api/customer',
      {
        accountId,
        ...customerData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.data.successful) {
      return response.data.data;
    } else {
      return { data: null, error: response.data.error.message };
    }
  } catch (error) {
    return { data: null, error: error.message || 'Request failed' };
  }
};

export const deleteCustomerById = async (customerId, token) => {
  try {
    if (!customerId) {
      throw new Error('customerId is required');
    }

    const response = await api.delete(`/api/customer/${customerId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.successful) {
      return response.data;
    } else {
      return {
        successful: false,
        message: response.data.error.message || 'Unknown error occurred',
      };
    }
  } catch (error) {
    console.error(
      'Failed to delete customer data:',
      error.response ? error.response.data : error.message
    );
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
    }
    return { successful: false, message: 'Failed to delete employee data' };
  }
};


export const updateGroup = async (token, customerId, group) => {
  try {
    if (!token) {
      return { account: null, statusCode: 401 };
    }
    const response = await api.patch(
      `/api/customer/${customerId}/group`,
      { group },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Request failed:', error);
    return null;
  }
};

export const filterCustomersByWallet = async (
  pageIndex,
  pageSize,
  type,
  token
) => {
  try {
    const response = await api.post(
      '/api/customer/sorting/walletamount',
      {
        pageSize: pageSize,
        pageIndex: pageIndex,
        type: type,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return {
      successful: false,
      error: {
        message:
          error.response?.data?.error?.message ||
          error.message ||
          'Something went wrong',
      },
    };
  }
};

export const updateCustomerById = async (
  token,
  customerId,
  apiKey,
  apiSecret,
  passphrase,
  platformType
) => {
  try {
    const response = await api.put(
      `/api/customer/${customerId}/short`,
      {
        apiKey,
        apiSecret,
        passphrase,
        platformType,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data?.successful) {
      return response.data.data;
    } else {
      throw new Error(
        response.data?.error?.message || 'Unknown error occurred'
      );
    }
  } catch (error) {
    return {
      successful: false,
      error: {
        message:
          error.response?.data?.error?.message ||
          error.message ||
          'Something went wrong',
      },
    };
  }
};

export const uploadFileCustomers = async (token) => {
  try {
    const response = await api.post(
      '/api/customer/export',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.successful) {
      return response.data;
    } else {
      throw new Error(response.data.error?.message || 'Unknown error occurred');
    }
  } catch (error) {
    return {
      successful: false,
      error: {
        message:
          error.response?.data?.error?.message ||
          error.message ||
          'Something went wrong',
      },
    };
  }
};


export const updateCustomerGroup= async (originalGroup, finalGroup, token) => {
  try {
    const response = await api.post(
      '/api/customer/group/bulk',
      {
        originalGroup, 
        finalGroup, 
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.data.successful) {
      return response.data;  
    } else {
      return { data: null, error: response.data.error.message };  
    }
  } catch (error) {
    return { data: null, error: error.message || 'Request failed' };  
  }
};

export const searchCustomers = async (
  pageIndex,
  pageSize,
  token,
  text
) => {
  try {
    const response = await api.post(
      '/api/customer/search/fullname',
      {
      text,
      pageSize,
      pageIndex,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    return {
      successful: false,
      error: {
        message:
          error.response?.data?.error?.message ||
          error.message ||
          'Something went wrong',
      },
    };
  }
};

export const searchExclusiveCustomers = async (
  pageIndex,
  pageSize,
  token,
  text
) => {
  try {
    const response = await api.post(
      '/api/customer/search/exclusive',
      {
      text,
      pageSize,
      pageIndex,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    return {
      successful: false,
      error: {
        message:
          error.response?.data?.error?.message ||
          error.message ||
          'Something went wrong',
      },
    };
  }
};
