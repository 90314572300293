/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { getAllCountries } from "../../../api/countryApi";
import DropDownCountry from "../../shared/DropDownCountry";
import Gender from "../../../static/enums/genderEnum";
import RadioValue from "../../shared/RadioValue";
import { PlatformType } from "../../../static/enums/platformTypeEnum";
import Cookies from "js-cookie";
import { updateCustomerById, updateGroup } from "../../../api/customerApi";
import ModalConfirmation from "../../shared/ModalConfirmation";
import { useNavigate } from "react-router-dom";
import { AccountType } from "../../../static/enums/accountTypeEmum";
import { updateStatus } from "../../../api/securityApi";
import DropDownSimple from "../../shared/DropDownSimple";
import { CustomerGroup } from "../../../static/enums/customerGroupEnum";
import Modal from "../../shared/Modal";

const CustomerForm = ({ customerData, accountType }) => {
   const navigate = useNavigate();
   const [initialData, setInitialData] = useState({
      apiKey: customerData?.apiKey || "",
      apiSecretKey: customerData?.apiSecret || "",
      passphrase: customerData?.passphrase || "",
      selectedExchange: customerData?.platformType || null,
   });

   const [selectedGender, setSelectedGender] = useState(
      customerData?.account?.gender || 1
   );
   const [firstName, setFirstName] = useState(
      customerData?.account?.firstName || ""
   );
   const [lastName, setLastName] = useState(
      customerData?.account?.lastName || ""
   );
   const [email, setEmail] = useState(customerData?.account?.email || "");
   const [age, setAge] = useState(customerData?.account?.age || "");
   const [mobile, setMobile] = useState(customerData?.account?.phone || "");
   const [countryDropdown, setCountryDropdown] = useState(false);
   const [country, setCountry] = useState({
      selectedFlag: customerData?.account?.country?.imageUrl || "",
      selectedName: customerData?.account?.country?.name || "",
      selectedId: customerData?.account?.country?.id || "",
   });
   const [agent, setAgent] = useState(customerData?.agent?.firstName || "");
   // eslint-disable-next-line no-unused-vars
   const [password, setPassword] = useState("");
   const [passphrase, setPassphrase] = useState(initialData.passphrase);
   const [selectedExchange, setSelectedExchange] = useState(
      initialData.selectedExchange
   );
   const [exchangeAccountUID, setExchangeAccountUID] = useState(
      customerData?.exchangeAccountUUID || ""
   );
   const [walletValue, setWalletValue] = useState(
      customerData?.currentWalletAmount
   );
   const [apiKey, setApiKey] = useState(initialData.apiKey);
   const [apiSecretKey, setApiSecretKey] = useState(initialData.apiSecretKey);
   const [inputValue, setInputValue] = useState(`${country.selectedName}`);
   const [countries, setCountries] = useState([]);

   const [modalVisible, setModalVisible] = useState(false);
   const [pendingStatus, setPendingStatus] = useState("");

   const [searchCountry, setSearchCountry] = useState("");
   const [modalType, setModalType] = useState("");
   const [status, setStatus] = useState(1);
   const [groupError, setGroupError] = useState(false);
   const [modalMessage, setModalMessage] = useState("");
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [selectedGroup, setSelectedGroup] = useState(
      customerData?.group || null
   );


   const handleModalClose = () => {
      setIsModalOpen(false);
      setModalVisible(false);
   };

   const customerGroupOptions = Object.entries(CustomerGroup)
      .filter(([key]) => key !== "Exclusive")
      .map(([key, value]) => ({
         value: value,
         label: key,
      }));

   const customerPlatformOptions = PlatformType.map(({ name, id }) => ({
      value: id,
      label: name,
   }));

   const [isExclusive, setIsExclusive] = useState(false);

   const handleCheckboxChange = () => {
      const newIsExclusive = !isExclusive;
      setIsExclusive(newIsExclusive);
      setSelectedGroup(newIsExclusive ? CustomerGroup.Exclusive : null);
      setGroupError(false);
   };

   useEffect(() => {
      if (selectedGroup === CustomerGroup.Exclusive) {
         setIsExclusive(true);
      } else {
         setIsExclusive(false);
      }
   }, [selectedGroup]);

   const handleGroupChange = (value) => {
      setSelectedGroup(value);
      setGroupError(!(value || !isExclusive));
   };

   const hasChanges =
      passphrase !== initialData.passphrase ||
      apiKey !== initialData.apiKey ||
      apiSecretKey !== initialData.apiSecretKey ||
      selectedExchange !== initialData.selectedExchange;

   useEffect(() => {
      const fetchCountries = async () => {
         try {
            const data = await getAllCountries(searchCountry);
            setCountries(data);
         } catch (error) {
            console.error("Failed to fetch countries:", error);
         }
      };

      fetchCountries();
   }, [searchCountry]);

   const isFirstRender = useRef(true);

   useEffect(() => {
   
      const patchGroup = async () => {
         const token = Cookies.get("accessToken");
         if (!token) {
            return { account: null, statusCode: 401 };
         }
         if (selectedGroup !== null) {
            try {
               const response = await updateGroup(
                  token,
                  customerData.id,
                  selectedGroup
               );
               console.log("Group updated successfully", response);
            } catch (error) {
               console.error("Error updating group:", error);
            }
         }
      };
   
      if (isFirstRender.current) {
         isFirstRender.current = false;
      } else {
         patchGroup();
      }
   }, [selectedGroup]);
   

   const openCountryDropdownHandler = (e) => {
      e.preventDefault();
      setCountryDropdown(!countryDropdown);
   };

   const closeCountryDropdownHandler = () => {
      setCountryDropdown(false);
   };

   const handleCountrySelect = (flagUrl, name, id) => {
      setCountry({
         selectedFlag: flagUrl,
         selectedName: name,
         selectedId: id,
      });
      setInputValue(name);
      closeCountryDropdownHandler();
   };

   const updateCustomerData = async () => {
      const customerId = customerData.id;
      if (hasChanges) {
         const token = Cookies.get("accessToken");
         try {
            const updatedData = await updateCustomerById(
               token,
               customerId,
               apiKey,
               apiSecretKey,
               passphrase,
               selectedExchange
            );
            console.log("Customer data updated:", updatedData);
         } catch (error) {
            console.error("Error updating customer data:", error);
         }
      }
   };

   const handleConfirm = async () => {
      let hasError = false;

      if (!selectedGroup && !isExclusive) {
         setGroupError(true);
         hasError = true;
      }

      if (hasError) {
         setModalMessage("Please fill in all required fields.");
         setModalType("error");
         setIsModalOpen(true);
         return;
      }

      const customerId = customerData.account.id;

      const token = Cookies.get("accessToken");

      const newStatus = modalType === "approve" ? 3 : 5;
      setStatus(newStatus);

      try {
         const statusUpdateResult = await updateStatus(
            token,
            customerId,
            newStatus
         );

         if (statusUpdateResult) {
            await updateCustomerData();
         } else {
            console.log("Error when updating status");
         }

         setModalVisible(false);
         navigate("/customers/approval");
      } catch (error) {
         console.error("Error during status or customer data update:", error);
      }
   };

   const handleReject = () => {
      setPendingStatus("Reject new customer application");
      setModalType("reject");
      setModalVisible(true);
   };

   const handleApprove = () => {
      setPendingStatus("Confirm new customer application");
      setModalType("approve");
      setModalVisible(true);
   };

   return (
      <div className="p-1 w-full">
         <form className="flex-col items-start bg-[#ffffff] md:py-10 py-5 rounded-lg md:px-16 px-3 shadow-md">
            {/* Form Grid */}
            <div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
               {/* First Name */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     First Name
                  </label>
                  <input
                     type="text"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
                     value={firstName}
                     onChange={(e) => setFirstName(e.target.value)}
                     disabled
                     //   ={accountType === AccountType.Employee}
                     //   required
                  />
               </div>
               {/* Last Name */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Last Name
                  </label>
                  <input
                     type="text"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
                     value={lastName}
                     onChange={(e) => setLastName(e.target.value)}
                     disabled
                     //   ={accountType === AccountType.Employee}
                     //   required
                  />
               </div>
               {/* Email */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Email
                  </label>
                  <input
                     type="email"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                     disabled
                     //   ={accountType === AccountType.Employee}
                     //   required
                  />
               </div>
            </div>

            <div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
               {/* Age */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Age
                  </label>
                  <input
                     type="number"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
                     value={age}
                     onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        setAge(newValue >= 0 ? newValue : 0);
                     }}
                     min={0}
                     disabled
                     //   ={accountType === AccountType.Employee}
                     //   required
                  />
               </div>
               {/* Mobile */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Mobile
                  </label>
                  <input
                     type="text"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
                     value={mobile}
                     onChange={(e) => setMobile(e.target.value)}
                     disabled
                     //   ={accountType === AccountType.Employee}
                     //   required
                  />
               </div>
               {/* Country */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Country
                  </label>
                  <div>
                     <button
                        onClick={openCountryDropdownHandler}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md text-left disabled:bg-mc_white"
                        disabled
                        // ={accountType === AccountType.Employee}
                     >
                        {country.selectedId ? (
                           <span>{country.selectedName}</span>
                        ) : (
                           <span className="text-xs">No selected country</span>
                        )}
                     </button>
                     {countryDropdown && (
                        <DropDownCountry
                           countries={countries}
                           onCountrySelect={handleCountrySelect}
                           onClose={closeCountryDropdownHandler}
                           value={inputValue}
                           searchValue={searchCountry}
                           setSearchValue={setSearchCountry}
                           onChange={(e) => setInputValue(e.target.value)}
                        />
                     )}
                  </div>
               </div>
            </div>

            <div className="grid grid-cols-3 md:gap-10 gap-3 mb-8 ">
               {/* Agent */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Agent
                  </label>
                  <input
                     type="text"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
                     value={agent}
                     onChange={(e) => setAgent(e.target.value)}
                     disabled
                     // ={accountType === AccountType.Employee}
                  />
               </div>
               {/* Gender */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700 mb-4">
                     Gender
                  </label>
                  <div className="mt-1 flex-row items-center">
                     <RadioValue
                        name="gender"
                        items={Gender}
                        value={selectedGender}
                        onChange={setSelectedGender}
                        disabled
                        // ={accountType === AccountType.Employee}
                     />
                  </div>
               </div>
               {/* Group */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700 mb-1">
                     Group
                  </label>
                  <DropDownSimple
                     options={customerGroupOptions}
                     selectedValue={selectedGroup}
                     onChange={handleGroupChange}
                     name={"Select Group"}
                     disable={isExclusive}
                  />
                  {groupError && (
                     <span className="text-red-500 text-xs">
                        Please select group or choose Exclusive
                     </span>
                  )}
               </div>
            </div>

            <hr className="my-10" />

            <div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
               {/* Exchange */}
               <div>
                  <label className="block mb-1 md:text-sm text-[6px] font-medium text-gray-700">
                     Exchange
                  </label>
                  <DropDownSimple
                     options={customerPlatformOptions}
                     selectedValue={selectedExchange}
                     onChange={setSelectedExchange}
                     name={"Select Exchange"}
                  />
               </div>
               {/* Exchange Account UID */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Exchange Account UID
                  </label>
                  <input
                     type="text"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
                     value={exchangeAccountUID}
                     onChange={(e) => setExchangeAccountUID(e.target.value)}
                     disabled
                     //   ={accountType === AccountType.Employee}
                     //   required
                  />
               </div>
               {/* Wallet Value */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Wallet Value
                  </label>
                  <input
                     type="text"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
                     value={walletValue}
                     onChange={(e) => setWalletValue(e.target.value)}
                     disabled
                     //   ={accountType === AccountType.Employee}
                     //   required
                  />
               </div>
            </div>

            <div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
               {/* API Key */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     API Key
                  </label>
                  <input
                     type="text"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
                     value={apiKey}
                     onChange={(e) => setApiKey(e.target.value)}
                  />
               </div>
               {/* API Secret Key */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     API Secret Key
                  </label>
                  <input
                     type="text"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
                     value={apiSecretKey}
                     onChange={(e) => setApiSecretKey(e.target.value)}
                  />
               </div>
               {/* Password */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Passphrase
                  </label>
                  <input
                     type="text"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
                     value={passphrase}
                     onChange={(e) => setPassphrase(e.target.value)}
                  />
               </div>
            </div>
            <div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
               <div className="flex items-end mb-3">
                  <label className="md:text-base text-[6px] font-medium text-gray-700 flex items-center">
                     <input
                        type="checkbox"
                        className="w-5 h-5"
                        checked={isExclusive}
                        onChange={handleCheckboxChange}
                     />
                     <span className="ml-5">Is an exclusive customer?</span>
                  </label>
               </div>
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Password
                  </label>
                  <input
                     type="password"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
                     value={"********"}
                     onChange={(e) => setPassword(e.target.value)}
                     disabled
                  />
               </div>
            </div>
            <hr className="my-10" />
            <div className="flex justify-end space-x-5 ">
               <button
                  type="button"
                  onClick={handleReject}
                  className="w-1/3 bg-red-500 p-3 rounded-full hover:bg-red-600 md:text-lg text-[8зx] disabled:bg-red-300 disabled:cursor-not-allowed"
                  disabled={accountType === AccountType.Employee}
               >
                  Reject
               </button>
               <button
                  type="button"
                  onClick={handleApprove}
                  className="w-1/3 bg-green-500 p-3 rounded-full hover:bg-green-600 md:text-base text-[8px] disabled:bg-green-300 disabled:cursor-not-allowed"
                  disabled={accountType === AccountType.Employee}
               >
                  Approve
               </button>
            </div>
         </form>
         <ModalConfirmation
            isVisible={modalVisible}
            onConfirm={handleConfirm}
            onCancel={() => setModalVisible(false)}
            pendingStatus={pendingStatus}
            modalType={modalType}
         />
         <Modal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            message={modalMessage}
            type={modalType}
         />
      </div>
   );
};

export default CustomerForm;
