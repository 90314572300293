import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../shared/Modal";
import { getAllCountries } from "../../../../api/countryApi";
import DropDownCountry from "../../../shared/DropDownCountry";
import Gender from "../../../../static/enums/genderEnum";
import RadioValue from "../../../shared/RadioValue";
import { PlatformType } from "../../../../static/enums/platformTypeEnum";
import { createAccount } from "../../../../api/accountApi";
import {
   createCustomerByAdmin,
   updateGroup,
} from "../../../../api/customerApi";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { getAgentData } from "../../../../api/agentApi";
import DropdownSelect from "../../../shared/DropDownSelect";
import SearchDropdown from "../../../additional/SearchDropdown";
import DropDownSimple from "../../../shared/DropDownSimple";
import { CustomerGroup } from "../../../../static/enums/customerGroupEnum";

const AddCustomerForm = () => {
   const navigate = useNavigate();

   const [selectedGender, setSelectedGender] = useState(1);
   const [password, setPassword] = useState("");
   const [firstName, setFirstName] = useState("");
   const [lastName, setLastName] = useState("");
   const [email, setEmail] = useState("");
   const [age, setAge] = useState("");
   const [phone, setPhone] = useState("");
   const [exchange, setExchange] = useState(null);
   const [exchangeAccountUUID, setExchangeAccountUUID] = useState("");
   const [passphrase, setPassphrase] = useState("");
   const [apiKey, setApiKey] = useState("");
   const [apiSecretKey, setApiSecretKey] = useState("");
   const [countries, setCountries] = useState([]);

   const [searchCountry, setSearchCountry] = useState("");
   const [countryDropdown, setCountryDropdown] = useState(false);
   const [country, setCountry] = useState({
      selectedFlag: "",
      selectedName: "",
      selectedCode: "",
      selectedId: "",
   });
   const [inputValue, setInputValue] = useState(`${country.selectedName}`);

   const [countryCodeDropdown, setCountryCodeDropdown] = useState(false);
   const [countryCode, setCountryCode] = useState({
      selectedFlag: "",
      selectedCode: "",
   });
   const buttonRef = useRef(null);
   const [totalWidth, setTotalWidth] = useState(0);
   const inputRef = useRef();
   const [inputCodeValue, setInputCodeValue] = useState(
      `${countryCode.selectedCode}`
   );

   const [isModalOpen, setIsModalOpen] = useState(false);
   const [modalMessage, setModalMessage] = useState("");
   const [modalType, setModalType] = useState("success");

   const [agents, setAgents] = useState([]);
   const [selectedAgent, setSelectedAgent] = useState(null);
   const [agentError, setAgentError] = useState(false);
   const [countryError, setCountryError] = useState(false);
   const [groupError, setGroupError] = useState(false);
   const [exchangeError, setExchangeError] = useState(false);
   const [selectedGroup, setSelectedGroup] = useState(null);
   const [isExclusive, setIsExclusive] = useState(false);
   

   const handleCheckboxChange = () => {
    const newIsExclusive = !isExclusive;
    setIsExclusive(newIsExclusive);
    setSelectedGroup(newIsExclusive ? CustomerGroup.Exclusive : null);
    setGroupError(false);
  };
  

   const handleAgentChange = (value) => {
      setSelectedAgent(value);
      setAgentError(!value);
   };

   const handleGroupChange = (value) => {
      setSelectedGroup(value);
      setGroupError(!(value || !isExclusive));
   };

   const handleExchangeChange = (value) => {
      setExchange(value);
      setExchangeError(!value);
   };

   useEffect(() => {
      const token = Cookies.get("accessToken");
      if (!token) {
         console.error("No token found");
         return;
      }
      const fetchAgents = async () => {
         const response = await getAgentData(token, 0, 100);
         const agent = response.data;
         setAgents(
            agent.map((a) => ({
               value: a.id,
               label: `${a.account.firstName} ${a.account.lastName}`,
            }))
         );
      };

      fetchAgents();
   }, []);

   const customerGroupOptions = Object.entries(CustomerGroup)
      .filter(([key]) => key !== "Exclusive")
      .map(([key, value]) => ({
         value: value,
         label: key,
      }));

   const customerExchangeOptions = Object.entries(PlatformType).map(
      ([key, value]) => ({
         value: value.id,
         label: value.name,
      })
   );

   useEffect(() => {
      const fetchCountries = async () => {
         try {
            const data = await getAllCountries(searchCountry);
            setCountries(data);
         } catch (error) {
            console.error("Failed to fetch countries:", error);
         }
      };

      fetchCountries();
   }, [searchCountry]);

   const openCountryDropdownHandler = (e) => {
      e.preventDefault();
      setCountryDropdown(!countryDropdown);
   };

   const closeCountryDropdownHandler = () => {
      setCountryDropdown(false);
   };

   const openCountryCodeDropdownHandler = (e) => {
      e.preventDefault();
      setCountryCodeDropdown(!countryCodeDropdown);
   };

   const closeCountryCodeDropdownHandler = () => {
      setCountryCodeDropdown(false);
   };
   const updateWidth = () => {
      if (buttonRef.current && inputRef.current) {
         const buttonWidth = buttonRef.current.getBoundingClientRect().width;
         const inputWidth = inputRef.current.getBoundingClientRect().width;
         setTotalWidth(buttonWidth + inputWidth);
      }
   };

   useEffect(() => {
      updateWidth();

      window.addEventListener("resize", updateWidth);

      return () => {
         window.removeEventListener("resize", updateWidth);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   // const inputRef = useMask({
   // 	mask: `${country.selectedCode} (__) ___-__-__`,
   // 	replacement: { _: /\d/ },
   // });

   useEffect(() => {
      if (inputRef.current) {
         inputRef.current.value = countryCode.selectedCode;
      }
   }, [countryCode.selectedCode, inputRef]);

   const handleKeyDown = (e) => {
      const input = inputRef.current;
      const selectionStart = input.selectionStart;

      if (
         selectionStart <= countryCode.selectedCode.length &&
         (e.key === "Backspace" || e.key === "Delete")
      ) {
         e.preventDefault();
      }

      if (selectionStart < countryCode.selectedCode.length) {
         e.preventDefault();
         input.setSelectionRange(
            countryCode.selectedCode.length,
            countryCode.selectedCode.length
         );
      }
   };

   const handleCountrySelect = (flagUrl, name, id, code) => {
      setCountry({
         selectedFlag: flagUrl,
         selectedName: name,
         selectedCode: code,
         selectedId: id,
      });
      setCountryError(false);
      closeCountryDropdownHandler();
   };

   const handleCountryCodeSelect = (flagUrl, code) => {
      setCountryCode({
         selectedFlag: flagUrl,
         selectedCode: code,
      });

      closeCountryDropdownHandler();
   };

   const handlePhoneChange = (e) => {
      const { value } = e.target;
      const numericValue = value.replace(/(?!^)\D/g, "");
      e.target.value = numericValue;
      const newPhone = e.target.value;
      setPhone(newPhone);
   };

   const handleSubmit = async (event) => {
      event.preventDefault();

      let hasError = false;

      if (!selectedAgent) {
         setAgentError(true);
         hasError = true;
      }

      if (!exchange) {
         setExchangeError(true);
         hasError = true;
      }

      if (!country.selectedId) {
         setCountryError(true);
         hasError = true;
      }

      if (!selectedGroup && !isExclusive ) {
         setGroupError(true);
         hasError = true;
      }

      if (hasError) {
         setModalMessage("Please fill in all required fields.");
         setModalType("error");
         setIsModalOpen(true);
         return;
      }

      const accountData = {
         firstName,
         lastName,
         email,
         age,
         phone,
         countryId: country.selectedId,
         gender: selectedGender,
         password,
         accountType: 1,
      };
      try {
         const token = Cookies.get("accessToken");
         if (!token) {
            return { account: null, statusCode: 401 };
         }
         const accountId = await createAccount(accountData, token);
         if (accountId && accountId.error) {
            setModalMessage(
               accountId.error || "Failed to create customer. Please try again."
            );
            setModalType("error");
            setIsModalOpen(true);
            return;
         }

         const customerData = {
            platformType: exchange,
            apiKey: apiKey,
            applicationSource: 1,
            apiSecret: apiSecretKey,
            passphrase: passphrase,
            exchangeAccountUUID: exchangeAccountUUID,
            accountId: accountId,
            agentId: selectedAgent,
         };

         const customer = await createCustomerByAdmin(
            accountId,
            customerData,
            token
         );
         if (!customer) {
            setModalMessage(
               customer.error || "Failed to create customer. Please try again."
            );
            setModalType("error");
         } else {
            const response = await updateGroup(
               token,
               customer.id,
               selectedGroup
            );
            if (response.successful) {
               setModalMessage("Customer created successfully!");
               setModalMessage(
                  <div className="flex flex-col justify-center space-y-5">
                     <span>New customer added successfully!</span>
                     <span>Pending approval </span>
                  </div>
               );
               setModalType("success");
            } else {
               setModalMessage(
                  response.error.message ||
                     "Failed to create customer. Please try again."
               );
               setModalType("error");
            }
         }

         setIsModalOpen(true);
      } catch (error) {
         console.error("Error during form submission:", error);
         setModalMessage("An error occurred. Please try again.");
         setModalType("error");
         setIsModalOpen(true);
      }
   };

   const handleModalClose = () => {
      setIsModalOpen(false);
      if (modalType === "success") {
         navigate("/customers");
      }
   };

   return (
      <div className="p-1 min-w-full">
         <form
            onSubmit={handleSubmit}
            className="flex-col items-start bg-[#ffffff] md:py-10 py-5 rounded-lg md:px-16 px-3 shadow-md"
         >
            {/* Form Grid */}
            <div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
               {/* First Name */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     First Name
                  </label>
                  <input
                     type="text"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                     value={firstName}
                     onChange={(e) => {
                        const value = e.target.value;
                        const formattedValue = value
                           .split(" ")
                           .map(
                              (word) =>
                                 word.charAt(0).toUpperCase() + word.slice(1)
                           )
                           .join(" ");
                        setFirstName(formattedValue);
                     }}
                     required
                  />
               </div>
               {/* Last Name */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Last Name
                  </label>
                  <input
                     type="text"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                     value={lastName}
                     onChange={(e) => {
                        const value = e.target.value;
                        const formattedValue = value
                           .split(" ")
                           .map(
                              (word) =>
                                 word.charAt(0).toUpperCase() + word.slice(1)
                           )
                           .join(" ");
                        setLastName(formattedValue);
                     }}
                     required
                  />
               </div>
               {/* Email */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Email
                  </label>
                  <input
                     type="email"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                     required
                  />
               </div>
            </div>

            <div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
               {/* Age */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Age
                  </label>
                  <input
                     type="number"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                     value={age}
                     onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        setAge(newValue >= 0 ? newValue : 0);
                     }}
                     min={0}
                     required
                  />
               </div>
               {/* Mobile */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Mobile
                  </label>
                  <div className="flex mt-1">
                     <div className="w-1/4">
                        <button
                           onClick={openCountryCodeDropdownHandler}
                           className={`h-11 w-full p-3 flex flex-col justify-center items-center rounded-l-[5px] border border-mc_light_grey outline-none }`}
                           ref={buttonRef}
                        >
                           {countryCode.selectedFlag ? (
                              <img
                                 src={countryCode.selectedFlag}
                                 alt="Selected flag"
                                 className="h-auto"
                              />
                           ) : (
                              <div className="flex items-center">
                                 <span className="text-xs">code</span>{" "}
                                 <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="size-5"
                                 >
                                    <path
                                       fillRule="evenodd"
                                       d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                                       clipRule="evenodd"
                                    />
                                 </svg>
                              </div>
                           )}
                        </button>
                        {countryCodeDropdown && (
                           <SearchDropdown
                              countries={countries}
                              onCountrySelect={handleCountryCodeSelect}
                              onClose={closeCountryCodeDropdownHandler}
                              value={inputCodeValue}
                              searchValue={searchCountry}
                              setSearchValue={setSearchCountry}
                              onChange={(e) =>
                                 setInputCodeValue(e.target.value)
                              }
                              style={{ width: `${totalWidth}px` }}
                           />
                        )}
                     </div>
                     <input
                        id="phone"
                        name="phone"
                        type="text"
                        placeholder="Phone No"
                        ref={inputRef}
                        onKeyDown={handleKeyDown}
                        onChange={handlePhoneChange}
                        required
                        className={`block h-11 w-full p-3 font-normal rounded-r-[5px] border border-mc_light_grey outline-none placeholder:text-sm `}
                     />
                  </div>
               </div>
               {/* Country */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Country
                  </label>
                  <div>
                     <button
                        onClick={openCountryDropdownHandler}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md text-left"
                     >
                        {country.selectedId ? (
                           <span>{country.selectedName}</span>
                        ) : (
                           <span className="text-xs">No selected country</span>
                        )}
                     </button>
                     {countryDropdown && (
                        <DropDownCountry
                           countries={countries}
                           onCountrySelect={handleCountrySelect}
                           onClose={closeCountryDropdownHandler}
                           value={inputValue}
                           searchValue={searchCountry}
                           setSearchValue={setSearchCountry}
                           onChange={(e) => setInputValue(e.target.value)}
                        />
                     )}
                  </div>
                  {countryError && (
                     <span className="text-red-500 text-xs">
                        Please select country
                     </span>
                  )}
               </div>
            </div>

            <div className="grid grid-cols-3 md:gap-10 gap-3 mb-8 ">
               {/* Agent */}
               <div>
                  <div>
                     <label className="block md:text-sm text-[6px] mb-1 font-medium text-gray-700">
                        Agent
                     </label>
                     <DropdownSelect
                        options={agents}
                        selectedValue={selectedAgent}
                        onChange={handleAgentChange}
                     />
                  </div>
                  {agentError && (
                     <span className="text-red-500 text-xs">
                        Please select agent
                     </span>
                  )}
               </div>
               {/* Gender */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700 mb-4">
                     Gender
                  </label>
                  <div className="mt-1 flex-row items-center">
                     <RadioValue
                        name="gender"
                        items={Gender}
                        value={selectedGender}
                        onChange={setSelectedGender}
                     />
                  </div>
               </div>
               {/* Group */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700 mb-1">
                     Group
                  </label>
                  <DropDownSimple
                     options={customerGroupOptions}
                     selectedValue={selectedGroup}
                     onChange={handleGroupChange}
                     name={"Select Group"}
                     disable={isExclusive}
                  />
                  {groupError && (
                     <span className="text-red-500 text-xs">
                        Please select group or choose Exclusive
                     </span>
                  )}
               </div>
            </div>
            <hr className="my-10" />

            <div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
               {/* Exchange */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Exchange
                  </label>
                  <DropDownSimple
                     options={customerExchangeOptions}
                     selectedValue={exchange}
                     onChange={handleExchangeChange}
                     name={"Select Exchange"}
                  />
                  {exchangeError && (
                     <span className="text-red-500 text-xs">
                        Please select exchange
                     </span>
                  )}
               </div>
               {/* Exchange Account UID */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Exchange Account UID
                  </label>
                  <input
                     type="text"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                     value={exchangeAccountUUID}
                     onChange={(e) => setExchangeAccountUUID(e.target.value)}
                     required
                  />
               </div>
               {/* Passphrase */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Passphrase
                  </label>
                  <input
                     type="text"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                     value={passphrase}
                     onChange={(e) => setPassphrase(e.target.value)}
                  />
               </div>
            </div>

            <div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
               {/* Password */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     Password
                  </label>
                  <input
                     type="password"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                     value={password}
                     onChange={(e) => setPassword(e.target.value)}
                     required
                  />
               </div>
               {/* API Key */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     API Key
                  </label>
                  <input
                     type="text"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                     value={apiKey}
                     onChange={(e) => setApiKey(e.target.value)}
                     required
                  />
               </div>
               {/* API Secret Key */}
               <div>
                  <label className="block md:text-sm text-[6px] font-medium text-gray-700">
                     API Secret Key
                  </label>
                  <input
                     type="text"
                     className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                     value={apiSecretKey}
                     onChange={(e) => setApiSecretKey(e.target.value)}
                     required
                  />
               </div>
            </div>
            <div>
               <div className="flex items-end mb-3">
                  <label className="md:text-base text-[6px] font-medium text-gray-700 flex items-center">
                     <input
                        type="checkbox"
                        className="w-5 h-5"
                        checked={isExclusive}
                        onChange={handleCheckboxChange}
                     />
                     <span className="ml-5">Is an exclusive customer?</span>
                  </label>
               </div>
            </div>
            <hr className="my-10" />
            <div className="flex justify-end">
               <button
                  type="submit"
                  className="w-1/3 bg-green-500 text-white p-3 rounded-full hover:bg-green-600 md:text-base text-[8px]"
               >
                  Submit for Approval
               </button>
            </div>
            <p className="md:text-xs text-[6px] text-red-500 mt-4 text-end flex justify-end font-semibold">
               The customer account will be activated <br /> only upon
               management approval.
            </p>
         </form>
         <Modal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            message={modalMessage}
            type={modalType}
         />
      </div>
   );
};

export default AddCustomerForm;
