import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../shared/Modal";
import { getAllCountries } from "../../../../api/countryApi";
import DropDownCountry from "../../../shared/DropDownCountry";
import Gender from "../../../../static/enums/genderEnum";
import RadioValue from "../../../shared/RadioValue";
import {createAgent } from "../../../../api/agentApi";
import Cookies from "js-cookie";
import { createAccount } from "../../../../api/accountApi";
import { useNavigate } from "react-router-dom";
import SearchDropdown from "../../../additional/SearchDropdown";

const AddAgentForm = () => {
  const [countryDropdown, setCountryDropdown] = useState(false);
  const [country, setCountry] = useState({
    selectedFlag: "",
    selectedName: "",
    selectedCode: "",
    selectedId: "",
  });
  const [selectedGender, setSelectedGender] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [countries, setCountries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("success");
  const [searchCountry, setSearchCountry] = useState("");

	const [countryCodeDropdown, setCountryCodeDropdown] = useState(false);
	const [countryCode, setCountryCode] = useState({
		selectedFlag: '',
		selectedCode: '',
	});
	const buttonRef = useRef(null);
	const [totalWidth, setTotalWidth] = useState(0);
	const inputRef = useRef();
	const [inputCodeValue, setInputCodeValue] = useState(
		`${countryCode.selectedCode}`
	);


  // useEffect(() => {
	// 	if (inputRef.current) {
	// 		inputRef.current.value = country.selectedCode;
	// 	}
	// }, [country.selectedCode, inputRef]);

  // useEffect(() => {
  //   if (country.selectedCode) {
  //     setPhone(country.selectedCode); 
  //   }
  // }, [country.selectedCode]);

  const navigate = useNavigate()

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const data = await getAllCountries(searchCountry);
        setCountries(data || []);
      } catch (error) {
        console.error("Failed to fetch countries:", error);
      }
    };

    fetchCountries();
  }, [searchCountry]);


  const openCountryDropdownHandler = (e) => {
    e.preventDefault();
    setCountryDropdown(!countryDropdown);
  };

  const closeCountryDropdownHandler = () => {
    setCountryDropdown(false);
  };

	const openCountryCodeDropdownHandler = (e) => {
		e.preventDefault();
		setCountryCodeDropdown(!countryCodeDropdown);
	};

	const closeCountryCodeDropdownHandler = () => {
		setCountryCodeDropdown(false);
	};
	const updateWidth = () => {
		if (buttonRef.current && inputRef.current) {
			const buttonWidth = buttonRef.current.getBoundingClientRect().width;
			const inputWidth = inputRef.current.getBoundingClientRect().width;
			setTotalWidth(buttonWidth + inputWidth);
		}
	};

	useEffect(() => {
		updateWidth();

		window.addEventListener('resize', updateWidth);

		return () => {
			window.removeEventListener('resize', updateWidth);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const inputRef = useMask({
	// 	mask: `${country.selectedCode} (__) ___-__-__`,
	// 	replacement: { _: /\d/ },
	// });

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.value = countryCode.selectedCode;
		}
	}, [countryCode.selectedCode, inputRef]);

	const handleKeyDown = (e) => {
		const input = inputRef.current;
		const selectionStart = input.selectionStart;

		if (
			selectionStart <= countryCode.selectedCode.length &&
			(e.key === 'Backspace' || e.key === 'Delete')
		) {
			e.preventDefault();
		}

		if (selectionStart < countryCode.selectedCode.length) {
			e.preventDefault();
			input.setSelectionRange(
				countryCode.selectedCode.length,
				countryCode.selectedCode.length
			);
		}
	};

  const handleCountrySelect = (flagUrl, name, id, code) => {
    setCountry({
			selectedFlag: flagUrl,
			selectedName: name,
			selectedCode: code,
			selectedId: id,
		});

		closeCountryDropdownHandler();
  
  };

  const handleCountryCodeSelect = (flagUrl, code) => {
		setCountryCode({
			selectedFlag: flagUrl,
			selectedCode: code,
		});

		closeCountryDropdownHandler();
	};

	const handlePhoneChange = (e) => {
		const { value } = e.target;
		const numericValue = value.replace(/(?!^)\D/g, '');
		e.target.value = numericValue;
		const newPhone = e.target.value;
		setPhone(newPhone);
	};



  const handleSubmit = async (event) => {
    event.preventDefault();

    const accountData = {
      firstName,
      lastName,
      email,
      age,
      phone,
      countryId: country.selectedId,
      gender: selectedGender,
      password,
      accountType: 2,
    };

    try {
      const token = Cookies.get("accessToken");
      if (!token) {
        return { account: null, statusCode: 401 };
      }
      const accountId = await createAccount(accountData, token);
      if (accountId && accountId.error) {
        setModalMessage(
          accountId.error || 'Failed to create customer. Please try again.'
        );
        setModalType('error');
        setIsModalOpen(true);
        return;
      }

      const agentData = {
        accountId,
      };

      const agent = await createAgent(accountId, agentData, token);
      if (!agent) {
        setModalMessage("Failed to create agent. Please try again.");
        setModalType("error");
      } else {
        setModalMessage("Agent created successfully!");
        setModalType("success");
      }

      setIsModalOpen(true);
    } catch (error) {
      console.error("Error during form submission:", error);
      setModalMessage("An error occurred. Please try again.");
      setModalType("error");
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    if (modalType === "success") {
      navigate("/agents");
    }
  };

  return (
    <div className="p-1">
      <form  onSubmit={handleSubmit}>
        {/* Form Grid */}
        <div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
          {/* Full Name */}
          <div>
            <label className="block md:text-sm text-[6px] font-medium text-gray-700">
              First Name
            </label>
            <input
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              value={firstName}
              onChange={(e) => {
                const value = e.target.value;
                const formattedValue = value
                    .split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
                    .join(' '); 
                setFirstName(formattedValue);
              }}
              required
            />
          </div>
          {/* Last Name */}
          <div>
            <label className="block md:text-sm text-[6px] font-medium text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              value={lastName}
              onChange={(e) => {
                const value = e.target.value;
                const formattedValue = value
                    .split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
                    .join(' '); 
                setLastName(formattedValue);
            }}
            
              required
            />
          </div>
          {/* Email */}
          <div>
            <label className="block md:text-sm text-[6px] font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
          {/* Age */}
          <div>
            <label className="block md:text-sm text-[6px] font-medium text-gray-700">
              Age
            </label>
            <input
              type="number"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              value={age}
              onChange={(e) => {
                const newValue = parseInt(e.target.value, 10);
                setAge(newValue >= 0 ? newValue : 0);
              }}
              min={0}
              required
            />
          </div>
          {/* Mobile */}
          <div>
            <label className="block md:text-sm text-[6px] font-medium text-gray-700">
              Mobile
            </label>
            <div className="flex mt-1">
							<div className="w-1/4">
								<button
									onClick={openCountryCodeDropdownHandler}
									className={`h-11 w-full p-3 flex flex-col justify-center items-center rounded-l-[5px] border border-mc_light_grey outline-none }`}
									ref={buttonRef}
								>
									{countryCode.selectedFlag ? (
										<img
											src={countryCode.selectedFlag}
											alt="Selected flag"
											className="h-auto"
										/>
									) : (
										<div className="flex items-center">
											<span className="text-xs">code</span>{' '}
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
												className="size-5"
											>
												<path
													fillRule="evenodd"
													d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
													clipRule="evenodd"
												/>
											</svg>
										</div>
									)}
								</button>
								{countryCodeDropdown && (
									<SearchDropdown
										countries={countries}
										onCountrySelect={handleCountryCodeSelect}
										onClose={closeCountryCodeDropdownHandler}
										value={inputCodeValue}
										searchValue={searchCountry}
										setSearchValue={setSearchCountry}
										onChange={(e) => setInputCodeValue(e.target.value)}
										style={{ width: `${totalWidth}px` }}
									/>
								)}
							</div>
							<input
								id="phone"
								name="phone"
								type="text"
								placeholder="Phone No"
								ref={inputRef}
								onKeyDown={handleKeyDown}
								onChange={handlePhoneChange}
								className={`block h-11 w-full p-3 font-normal rounded-r-[5px] border border-mc_light_grey outline-none placeholder:text-sm `}
							/>
						</div>
          </div>
          {/* Country */}
          <div>
            <label className="block md:text-sm text-[6px] font-medium text-gray-700">
              Country
            </label>
            <div className="w-full">
              <button
                onClick={openCountryDropdownHandler}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md text-left"
              >
                {country.selectedId ? (
                  <span>{country.selectedName}</span>
                ) : (
                  <span className="text-xs">No selected country</span>
                )}
              </button>
              {countryDropdown && (
                <DropDownCountry
                  countries={countries}
                  onCountrySelect={handleCountrySelect}
                  onClose={closeCountryDropdownHandler}
                  value={inputValue}
                  searchValue={searchCountry}
                  setSearchValue={setSearchCountry}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              )}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 md:gap-10 gap-3 mb-8">
          {/* Gender */}
          <div className="col-start-1">
            <label className="block md:text-sm text-[6px] font-medium text-gray-700 mb-4">
              Gender
            </label>
            <div className="mt-1 flex-row items-center">
              <RadioValue
                name="gender"
                items={Gender}
                value={selectedGender}
                onChange={setSelectedGender}
              />
            </div>
          </div>
          {/* Password */}
          <div className="col-start-3">
            <label className="block md:text-sm text-[6px] font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <hr className="my-10" />
        <div className="flex justify-end">
          {/* Submit */}
          <div className="flex w-1/4 items-end">
            <button
              type="submit"
              className="w-full bg-green-500 font-medium rounded-full text-white py-3 hover:bg-green-600 transition duration-300 ease-in-out"
            >
              Save information 
            </button>
          </div>
        </div>
      </form>

      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        message={modalMessage}
        type={modalType}
      />
    </div>
  );
};

export default AddAgentForm;
