import React, { useEffect, useRef, useState } from "react";
import Dropdown from "./DropDown";
import {
   AmountType,
   EntryDirection,
   PositionMode,
   TakeProfitMode,
} from "../../../static/enums/strategiesEnums";
import RadioValue from "../../shared/RadioValue";
import BoolRadio from "./BoolRadio";
import { createStrategy, updateStrategy } from "../../../api/parametersApi";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import Modal from "../../shared/Modal";
import { AccountType } from "../../../static/enums/accountTypeEmum";
import CheckBox from "../../shared/CheckBox";
import { CustomerGroup } from "../../../static/enums/customerGroupEnum";
import { searchExclusiveCustomers } from "../../../api/customerApi";
import SearchDropDown from "../../shared/SearchDropDown";

const Settings = ({ strategyRecordData, onWebhookUrlChange, accountType }) => {
   const { strategyId } = useParams();
   // eslint-disable-next-line no-unused-vars
   const [isFormDisabled, setIsFormDisabled] = useState(
      accountType === AccountType.Employee ? true : false
   );
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [modalMessage, setModalMessage] = useState("");
   const [modalType, setModalType] = useState("success");
   const [selectedDirection, setSelectedDirection] = useState("");
   const [selectedProfitType, setSelectedProfitType] = useState("");
   const [selectedProfitMode, setSelectedProfitMode] = useState("");
   const [selectedStopLoss, setSelectedStopLoss] = useState("");
   const [selectedAdvanceStopLoss, setSelectedAdvanceStopLoss] =
      useState(false);
   const [selectedTrailing, setSelectedTrailing] = useState(false);
   const [selectedActivation, setSelectedActivation] = useState("");

   const [selectedSymbol, setSelectedSymbol] = useState("");
   const [strategyIdValue, setStrategyIdValue] = useState("");
   const [leverage, setLeverage] = useState("");
   const [tp1TargetLevel, setTp1TargetLevel] = useState("");
   const [tp2TargetLevel, setTp2TargetLevel] = useState("");
   const [tp3TargetLevel, setTp3TargetLevel] = useState("");
   const [tp1CloseVolume, setTp1CloseVolume] = useState("");
   const [tp2CloseVolume, setTp2CloseVolume] = useState("");
   const [tp3CloseVolume, setTp3CloseVolume] = useState("");
   const [stopLossValue, setStopLossValue] = useState("");
   const [activationValue, setActivationValue] = useState("");
   const [activationSelection, setActivationSelection] = useState("");
   const [positionCloseAmount, setPositionCloseAmount] = useState("");
   const [positionCloseQty, setPositionCloseQty] = useState("");
   const [selectedOption, setSelectedOption] = useState("");
   const [errors, setErrors] = useState("");
   const [isUpdateMode, setIsUpdateMode] = useState(false);
   const [selectedGroups, setSelectedGroups] = useState([]);
   const [dropdownOptions, setDropdownOptions] = useState([]);
   const [searchValue, setSearchValue] = useState("");
   const [dropdownVisible, setDropdownVisible] = useState(false);
   const [investmentValues, setInvestmentValues] = useState({});
   const [selectedCustomer, setSelectedCustomer] = useState(null);

   const handleSelectCustomer = (customer) => {
      setSelectedCustomer(customer);
      setDropdownVisible(false);
      if (customer) {
         setErrors((prevErrors) => ({ ...prevErrors, searchDropdown: null }));
      }
   };

   const loadExclusiveCustomers = async (text) => {
      const token = Cookies.get("accessToken");
      try {
         const response = await searchExclusiveCustomers(0, 100, token, text);
         if (response.successful) {
            const options = response.data.data.map((customer) => ({
               value: customer.id,
               label: `${customer.account.firstName} ${customer.account.lastName}`,
            }));
            setDropdownOptions(options);
         } else {
            console.error("Error fetching customers:", response.error.message);
         }
      } catch (error) {
         console.error("API Error:", error);
      }
   };

   useEffect(() => {
      loadExclusiveCustomers(searchValue);
   }, [searchValue]);

   const filteredAmountType = AmountType.filter(
      (item) => item.value === 1 || item.value === 3
   );

   const handleAdvanceStopLossChange = (value) => {
      setSelectedAdvanceStopLoss(value);
   };

   const customerGroupOptions = Object.entries(CustomerGroup).map(
      ([key, value]) => ({
         value: value,
         label: key,
      })
   );

   const handleSelect = (option) => {
      setSelectedOption(option);
      if (option) {
         setErrors((prevErrors) => ({ ...prevErrors, dropdown: null }));
      }
   };

   const handleTrailingChange = (value) => {
      setSelectedTrailing(value);
   };

   const handleInvestmentChange = (groupValue, type, value) => {
      setInvestmentValues((prevValues) => ({
         ...prevValues,
         [groupValue]: {
            ...prevValues[groupValue],
            [type]: value,
         },
      }));

      if (value) {
         setErrors((prevErrors) => ({
            ...prevErrors,
            [groupValue]: null,
         }));
      }
   };

   const isSecondGroupSelected = selectedGroups.includes(
      CustomerGroup.Internal
   );

   const handleCheckboxChange = (group) => {
      setSelectedGroups((prevSelectedGroups) => {
         const newSelectedGroups = prevSelectedGroups.includes(group)
            ? prevSelectedGroups.filter((g) => g !== group)
            : [...prevSelectedGroups, group];

         if (!newSelectedGroups.includes(group)) {
            setInvestmentValues((prevValues) => ({
               ...prevValues,
               [group]: { dollar: "", qty: "" },
            }));
         }
         if (!newSelectedGroups.includes(CustomerGroup.Exclusive)) {
            setSelectedCustomer("");
         }
         return newSelectedGroups;
      });
      setErrors((prevErrors) => {
         const { [group]: _, ...restErrors } = prevErrors;
         return restErrors;
      });
   };

   useEffect(() => {
      if (!isSecondGroupSelected) {
         setSelectedGroups((prevSelectedGroups) =>
            prevSelectedGroups.filter(
               (group) => group !== CustomerGroup.Exclusive
            )
         );
         setSelectedCustomer("");
         setInvestmentValues((prevValues) => {
            const updatedValues = { ...prevValues };
            delete updatedValues[CustomerGroup.Exclusive];
            return updatedValues;
         });
      }
   }, [isSecondGroupSelected]);

   const isInputDisabled = (groupValue) => !selectedGroups.includes(groupValue);

   useEffect(() => {
      if (strategyId) {
         setIsUpdateMode(true);
      } else {
         setIsUpdateMode(false);
      }
   }, [strategyId]);

   const findLabelByValue = (value) => {
      const stringValue = String(value);
      const option = dropdownOptions.find(
         (opt) => String(opt.value) === stringValue
      );
      return option ? option.label : "";
   };

   useEffect(() => {
      if (
         strategyRecordData?.exclusiveCustomerId &&
         dropdownOptions.length > 0
      ) {
         const value = String(strategyRecordData.exclusiveCustomerId);
         const label = findLabelByValue(value);
         setSelectedCustomer({ value, label });
      }
   }, [strategyRecordData.exclusiveCustomerId, dropdownOptions]);

   useEffect(() => {
      if (strategyRecordData) {
         setSelectedSymbol(strategyRecordData.symbol || "");
         setStrategyIdValue(strategyRecordData.id || "");

         if (strategyRecordData.groups) {
            const selectedGroups = strategyRecordData.groups.map(
               (group) => group.group
            );
            setSelectedGroups(selectedGroups);

            const initialInvestmentValues = {};
            strategyRecordData.groups.forEach((group) => {
               initialInvestmentValues[group.group] = {
                  dollar:
                     group.investAmount.type === 1
                        ? group.investAmount.amount
                        : "",
                  qty:
                     group.investAmount.type === 2
                        ? group.investAmount.amount
                        : "",
               };
            });
            setInvestmentValues(initialInvestmentValues);
         }

         setSelectedDirection(
            EntryDirection.find(
               (option) => option.value === strategyRecordData.entryDirection
            )?.value
         );
         setLeverage(strategyRecordData.leverage);
         setSelectedOption(strategyRecordData.positionMode);

         setSelectedProfitType(
            AmountType.find(
               (option) => option.value === strategyRecordData.takeProfitType
            )?.value
         );

         setSelectedProfitMode(
            AmountType.find(
               (option) => option.value === strategyRecordData.takeProfitMode
            )?.value
         );

         setTp1TargetLevel(strategyRecordData.tp1?.targetLevel);
         setTp2TargetLevel(strategyRecordData.tp2?.targetLevel);
         setTp3TargetLevel(strategyRecordData.tp3?.targetLevel);

         setTp1CloseVolume(strategyRecordData.tp1?.closeVolume);
         setTp2CloseVolume(strategyRecordData.tp2?.closeVolume);
         setTp3CloseVolume(strategyRecordData.tp3?.closeVolume);

         setSelectedStopLoss(
            AmountType.find(
               (option) => option.value === strategyRecordData.stopLoss?.type
            )?.value
         );

         setStopLossValue(strategyRecordData.stopLoss?.amount);

         setSelectedActivation(
            AmountType.find(
               (option) =>
                  option.value === strategyRecordData.positionActivation?.type
            )?.value
         );

         setSelectedAdvanceStopLoss(strategyRecordData.advanceStopLoss);
         setSelectedTrailing(strategyRecordData.trailingStop);

         if (strategyRecordData.positionClose?.type === 1) {
            setPositionCloseAmount(strategyRecordData.positionClose.amount);
            setPositionCloseQty("");
         } else if (strategyRecordData.positionClose?.type === 3) {
            setPositionCloseQty(strategyRecordData.positionClose.amount);
            setPositionCloseAmount("");
         }

         if (strategyRecordData.positionActivation?.type === 1) {
            setActivationValue("");
            setActivationSelection(
               strategyRecordData.positionActivation.amount
            );
         } else if (strategyRecordData.positionActivation?.type === 3) {
            setActivationSelection("");
            setActivationValue(strategyRecordData.positionActivation.amount);
         }
      }
   }, [strategyRecordData]);

   useEffect(() => {
      if (selectedActivation === 1) {
         setActivationValue("");
         setPositionCloseAmount("");
      } else if (selectedActivation === 3) {
         setActivationSelection("");
         setPositionCloseQty("");
      }
   }, [selectedActivation]);

   useEffect(() => {
      if (selectedProfitMode === 1) {
         setTp2TargetLevel("");
         setTp3TargetLevel("");
         setTp1CloseVolume("");
         setTp2CloseVolume("");
      }
   }, [selectedProfitMode]);

   useEffect(() => {
      if (selectedTrailing === false) {
         setActivationValue("");
         setActivationSelection("");
         setSelectedActivation("");
         setPositionCloseAmount("");
         setPositionCloseQty("");
      } else {
         setSelectedActivation(strategyRecordData.positionActivation?.type);
         if (strategyRecordData.positionActivation?.type === 1) {
            setActivationValue("");
            setActivationSelection(
               strategyRecordData.positionActivation?.amount
            );
            setPositionCloseQty(strategyRecordData.positionClose?.amount);
            setPositionCloseAmount("");
         } else if (strategyRecordData.positionActivation?.type === 3) {
            setActivationSelection("");
            setActivationValue(strategyRecordData.positionActivation?.amount);
            setPositionCloseQty("");
            setPositionCloseAmount(strategyRecordData.positionClose?.amount);
         }
      }
   }, [selectedTrailing, strategyRecordData]);

   const handleSubmit = async (event) => {
      event.preventDefault();

      let newErrors = {};

      if (!selectedSymbol) {
         newErrors.symbol = "Symbol/Token is required";
      }
      if (!strategyIdValue) {
         newErrors.strategyId = "Strategy ID is required";
      }
      if (!leverage) {
         newErrors.leverageValue = "Leverage is required";
      }
      if (!selectedOption) {
         newErrors.dropdown = "Please select an option";
      }

      if (
         selectedGroups.includes(CustomerGroup.Exclusive) &&
         !selectedCustomer
      ) {
         newErrors.searchDropdown = "Please select an option";
      }

      if (!tp1TargetLevel) {
         newErrors.tp1Target = "Leverage is required";
      }

      if (selectedProfitMode === 2) {
         if (!tp2TargetLevel) {
            newErrors.tp2Target =
               "All TP target levels are required when Profit Mode is 2";
         }
      }
      if (selectedProfitMode === 2) {
         if (!tp3TargetLevel) {
            newErrors.tp3Target =
               "All TP target levels are required when Profit Mode is 2";
         }
      }
      if (selectedProfitMode === 2) {
         if (!tp1CloseVolume) {
            newErrors.tp1Volume =
               "All TP target levels are required when Profit Mode is 2";
         }
      }
      if (selectedProfitMode === 2) {
         if (!tp2CloseVolume) {
            newErrors.tp2Volume =
               "All TP target levels are required when Profit Mode is 2";
         }
      }
      if (!stopLossValue) {
         newErrors.stopLoss = "Leverage is required";
      }
      if (selectedTrailing && !activationValue && !activationSelection) {
         newErrors.activation =
            "Activation value or selection is required when Trailing Stop is On";
      }

      if (selectedTrailing) {
         if (!positionCloseAmount && !positionCloseQty) {
            newErrors.positionClose =
               "Position Close amount or quantity is required";
         } else if (
            selectedActivation !== 1 &&
            (positionCloseAmount < 0.01 || positionCloseAmount > 10)
         ) {
            newErrors.positionClose = "Value must be between 0.01 and 10";
         }
      }

      selectedGroups.forEach((group) => {
         const dollarValue = investmentValues[group]?.dollar;
         const qtyValue = investmentValues[group]?.qty;

         if (!dollarValue && !qtyValue) {
            newErrors[group] =
               "Invest amount or quantity is required for this group";
         }
      });

      if (Object.keys(newErrors).length > 0) {
         setErrors(newErrors);
         setIsModalOpen(true);
         setModalMessage(
            <div className="flex flex-col justify-center space-y-5">
               <span>Setting Added Failed!</span>
               <span className="text-sm">
                  Please complete all required fields
               </span>
            </div>
         );
         setModalType("error");
         return;
      }

      let tp1Volume = Number(tp1CloseVolume || 0);
      let tp2Volume = Number(tp2CloseVolume || 0);
      let tp3Volume = Number(tp3CloseVolume || 0);

      if (selectedProfitMode === 1) {
         tp1Volume = 100;
         tp2Volume = 0;
         tp3Volume = 0;
      } else if (selectedProfitMode === 2) {
         if (tp1Volume + tp2Volume > 100) {
            setModalMessage("Enter correct values");
            setModalType("error");
            setIsModalOpen(true);
            return;
         }
         tp3Volume = 100 - tp1Volume - tp2Volume;
      }

      const formData = {
         symbol: selectedSymbol,
         id: strategyIdValue,
         groups: selectedGroups.map((groupValue) => ({
            group: groupValue,
            investAmount: {
               type: investmentValues[groupValue]?.dollar ? 1 : 2,
               amount: Number(
                  investmentValues[groupValue]?.dollar ||
                     investmentValues[groupValue]?.qty ||
                     0
               ),
            },
         })),
         entryDirection: selectedDirection,
         leverage: Number(leverage),
         positionMode: selectedOption,
         takeProfitType: Number(selectedProfitType),
         takeProfitMode: selectedProfitMode,
         tp1: {
            targetLevel: Number(tp1TargetLevel || 0),
            closeVolume: Number(tp1Volume || 0),
         },
         tp2: {
            targetLevel: Number(tp2TargetLevel || 0),
            closeVolume: Number(tp2Volume || 0),
         },
         tp3: {
            targetLevel: Number(tp3TargetLevel || 0),
            closeVolume: Number(tp3Volume || 0),
         },
         stopLoss: {
            type: Number(selectedStopLoss),
            amount: Number(stopLossValue),
         },
         advanceStopLoss: selectedAdvanceStopLoss,
         trailingStop: selectedTrailing,
         positionActivation: {
            type: Number(selectedActivation || 1),
            amount: Number(activationValue || activationSelection),
         },
         positionClose: {
            type: positionCloseAmount ? 3 : 1,
            amount: Number(positionCloseAmount || positionCloseQty),
         },
         status: 1,
         exclusiveCustomerId: String(selectedCustomer?.value || ""),
      };

      const token = Cookies.get("accessToken");
      if (!token) {
         console.error("No token found");
         return;
      }
      try {
         let webhookUrl = "";
         if (isUpdateMode && strategyId) {
            const response = await updateStrategy(strategyId, formData, token);
            webhookUrl = response.webhookUrl;
            setModalMessage("Strategy successfully updated");
            setModalType("success");
         } else {
            const response = await createStrategy(
               { ...formData, id: strategyIdValue },
               token
            );
            webhookUrl = response.webhookUrl;
            setStrategyIdValue(response.id);
            setModalMessage("Strategy successfully saved");
            setModalType("success");

            setIsFormDisabled(true);

            window.history.replaceState(null, "", `/strategy/${response.id}`);
         }
         onWebhookUrlChange(webhookUrl);
         setIsModalOpen(true);
      } catch (error) {
         setModalMessage("Setting Added Failed!");
         setModalType("error");
         if (error.response) {
            console.error("Error Response Data:", error.response);
         }
         setIsModalOpen(true);
      }
   };

   return (
      <div className="w-full bg-[#ffffff] md:py-10 py-5 rounded-lg md:px-16 px-3">
         <form
            onSubmit={handleSubmit}
            className={`flex-col items-start ${
               isFormDisabled ? " pointer-events-none" : ""
            }`}
         >
            <ul className="w-full space-y-9">
               <li className="grid grid-cols-3 li_style before:top-1/2">
                  <div className="flex-initial justify-end">
                     <label className="label_style">Symbol / Token</label>
                     <input
                        className={`input_style ${
                           errors.symbol ? "input_style-error" : ""
                        }`}
                        placeholder="BTCUSDT"
                        disabled={isUpdateMode}
                        value={selectedSymbol || ""}
                        onChange={(e) => {
                           setSelectedSymbol(e.target.value);
                           if (e.target.value) {
                              setErrors((prevErrors) => ({
                                 ...prevErrors,
                                 symbol: null,
                              }));
                           }
                        }}
                     />
                  </div>
                  <div className="flex-initial justify-end">
                     <label className="label_style">Strategy ID</label>
                     <input
                        className={`input_style ${
                           errors.strategyId ? "input_style-error" : ""
                        }`}
                        placeholder="BTC001"
                        value={strategyIdValue || ""}
                        disabled={isUpdateMode}
                        onChange={(e) => {
                           setStrategyIdValue(e.target.value);
                           if (e.target.value) {
                              setErrors((prevErrors) => ({
                                 ...prevErrors,
                                 strategyId: null,
                              }));
                           }
                        }}
                     />
                  </div>
               </li>

               <hr className="border-[#000000]" />
               <li className="grid grid-cols-3 li_style before:top-1/2">
                  <div className="flex-initial">
                     <label className="label_style">Entry Direction</label>
                     <div className="radio_style">
                        <RadioValue
                           name="direction"
                           items={EntryDirection}
                           value={Number(selectedDirection)}
                           onChange={setSelectedDirection}
                           disabled={isUpdateMode}
                        />
                     </div>
                  </div>
                  <div className="flex-initial">
                     <label className="label_style">Leverage</label>
                     <input
                        className={`input_style ${
                           errors.leverageValue ? "input_style-error" : ""
                        }`}
                        placeholder="50"
                        value={leverage}
                        disabled={isUpdateMode}
                        onChange={(e) => {
                           setLeverage(e.target.value);
                           if (e.target.value) {
                              setErrors((prevErrors) => ({
                                 ...prevErrors,
                                 leverageValue: null,
                              }));
                           }
                        }}
                     />
                  </div>
                  <div className="flex-initial">
                     <label className="label_style">Position Mode</label>
                     <div className="md:w-3/4 w-5/6">
                        <Dropdown
                           onSelect={handleSelect}
                           options={PositionMode}
                           selectedOption={selectedOption}
                           placeholder="Select option"
                           className={`${errors.dropdown ? "bg-red-500" : ""}`}
                           disabled={isUpdateMode}
                        />
                     </div>
                     {errors.dropdown && (
                        <p className="text-red-500 text-[10px]">
                           {errors.dropdown}
                        </p>
                     )}
                  </div>
               </li>
               <hr className="border-[#000000]" />
               <li className="grid grid-cols-3 li_style before:top-1/2">
                  <div className="flex-initial">
                     <label className="label_style">
                        Take Profile ($/%) - A
                     </label>
                     <div className="radio_style">
                        <RadioValue
                           name="profile1"
                           items={filteredAmountType}
                           value={Number(selectedProfitType)}
                           disabled={isUpdateMode}
                           onChange={setSelectedProfitType}
                        />
                     </div>
                  </div>
                  <div className="flex-initial">
                     <label className="label_style">
                        Take Profile (Full/Partial)
                     </label>
                     <div className="radio_style">
                        <RadioValue
                           name="profile2"
                           items={TakeProfitMode}
                           value={Number(selectedProfitMode)}
                           disabled={isUpdateMode}
                           onChange={setSelectedProfitMode}
                        />
                     </div>
                  </div>
               </li>
               <li className="li_style before:top-1/2">
                  <div className="bg-[#d8d8d8] rounded-lg py-7 pl-2 space-y-8">
                     <div className="grid grid-cols-3 ">
                        <div className="flex-initial justify-end">
                           <label className="label_style">TP 1</label>
                           <input
                              className={`input_style ${
                                 errors.tp1Target ? "input_style-error" : ""
                              }`}
                              placeholder="2%"
                              value={tp1TargetLevel || ""}
                              disabled={isUpdateMode}
                              onChange={(e) => {
                                 setTp1TargetLevel(e.target.value);
                                 if (e.target.value) {
                                    setErrors((prevErrors) => ({
                                       ...prevErrors,
                                       tp1Target: null,
                                    }));
                                 }
                              }}
                           />
                           <span className="note_span">
                              Based on Take Profile - A selection{" "}
                           </span>
                        </div>
                        <div className="flex-initial justify-end">
                           <label className="label_style">TP 2</label>
                           <input
                              className={`input_style ${
                                 errors.tp2Target ? "input_style-error" : ""
                              }`}
                              placeholder="4%"
                              value={tp2TargetLevel || ""}
                              disabled={
                                 selectedProfitMode === 1 || isUpdateMode
                              }
                              onChange={(e) => {
                                 setTp2TargetLevel(e.target.value);
                                 if (e.target.value) {
                                    setErrors((prevErrors) => ({
                                       ...prevErrors,
                                       tp2Target: null,
                                    }));
                                 }
                              }}
                           />
                        </div>
                        <div className="flex-initial justify-end">
                           <label className="label_style">TP 3</label>
                           <input
                              className={`input_style ${
                                 errors.tp3Target ? "input_style-error" : ""
                              }`}
                              placeholder="6%"
                              value={tp3TargetLevel}
                              onChange={(e) => {
                                 setTp3TargetLevel(e.target.value);
                                 if (e.target.value) {
                                    setErrors((prevErrors) => ({
                                       ...prevErrors,
                                       tp3Target: null,
                                    }));
                                 }
                              }}
                              disabled={
                                 selectedProfitMode === 1 || isUpdateMode
                              }
                           />
                        </div>
                     </div>
                     <div className="grid grid-cols-3 ">
                        <div className="flex-initial justify-end">
                           <label className="label_style">
                              TP 1 - Close Position (%){" "}
                           </label>
                           <input
                              className={`input_style ${
                                 errors.tp1Volume ? "input_style-error" : ""
                              }`}
                              placeholder="30%"
                              value={tp1CloseVolume || ""}
                              onChange={(e) => {
                                 setTp1CloseVolume(e.target.value);
                                 if (e.target.value) {
                                    setErrors((prevErrors) => ({
                                       ...prevErrors,
                                       tp1Volume: null,
                                    }));
                                 }
                              }}
                              disabled={
                                 selectedProfitMode === 1 || isUpdateMode
                              }
                           />
                        </div>
                        <div className="flex-initial justify-end">
                           <label className="label_style">
                              TP 2 - Close Position (%){" "}
                           </label>
                           <input
                              className={`input_style ${
                                 errors.tp2Volume ? "input_style-error" : ""
                              }`}
                              placeholder="6%"
                              value={tp2CloseVolume || ""}
                              onChange={(e) => {
                                 setTp2CloseVolume(e.target.value);
                                 if (e.target.value) {
                                    setErrors((prevErrors) => ({
                                       ...prevErrors,
                                       tp2Volume: null,
                                    }));
                                 }
                              }}
                              disabled={
                                 selectedProfitMode === 1 || isUpdateMode
                              }
                           />
                        </div>
                        <div className="flex-initial justify-end">
                           <label className="label_style">
                              TP 3 - Close Position (Balance)
                           </label>
                           <input
                              className=" bg-[#93d197] text-red-600 flex md:w-3/4 w-5/6 md:p-3 px-2 font-semibold py-2  rounded-md md:text-sm text-xs"
                              value="Balance"
                              readOnly
                           />
                        </div>
                     </div>
                  </div>
               </li>
               <hr className="border-[#000000]" />
               <li className="grid grid-cols-3 li_style before:top-1/2">
                  <div className="flex-initial">
                     <label className="label_style">Stop Loss ($/%) - A</label>
                     <div className="radio_style">
                        <RadioValue
                           name="stopLoss"
                           disabled={isUpdateMode}
                           items={filteredAmountType}
                           value={Number(selectedStopLoss)}
                           onChange={setSelectedStopLoss}
                        />
                     </div>
                  </div>
                  <div className="flex-initial justify-end">
                     <label className="label_style">Stop Loss</label>
                     <input
                        className={`input_style ${
                           errors.stopLoss ? "input_style-error" : ""
                        }`}
                        placeholder="2%"
                        value={stopLossValue || ""}
                        disabled={isUpdateMode}
                        onChange={(e) => {
                           setStopLossValue(e.target.value);
                           if (e.target.value) {
                              setErrors((prevErrors) => ({
                                 ...prevErrors,
                                 stopLoss: null,
                              }));
                           }
                        }}
                     />
                     <span className="note_span">
                        Based on Stop Loss - A selection{" "}
                     </span>
                  </div>
                  <div className="flex-initial">
                     <label className="md:text-[10px] xl:text-sm text-[7px] font-bold text-gray-700 block mb-1 md:ml-2">
                        Advance Stop Loss option
                     </label>
                     <div className="radio_style">
                        <BoolRadio
                           name="advanceStopLoss"
                           items={[
                              { label: "On", value: true },
                              { label: "Off", value: false },
                           ]}
                           value={selectedAdvanceStopLoss}
                           onChange={handleAdvanceStopLossChange}
                           disabled={isUpdateMode}
                        />
                     </div>
                     <span className="note_span">
                        Stop loss adjusts to entry price after TP 1
                     </span>
                  </div>
               </li>
               <hr className="border-[#000000]" />
               <li className="grid grid-cols-3 li_style before:top-1/2">
                  <div className="flex-initial">
                     <label className="label_style">
                        Trailing Stop (On/Off)
                     </label>
                     <div className="radio_style">
                        <BoolRadio
                           name="trailing"
                           items={[
                              { label: "On", value: true },
                              { label: "Off", value: false },
                           ]}
                           value={selectedTrailing}
                           onChange={handleTrailingChange}
                           disabled={isUpdateMode}
                        />
                     </div>
                  </div>
                  <div className="flex-initial">
                     <label className="label_style">
                        Activation based on ($ / %)- (A){" "}
                     </label>
                     <div className="radio_style">
                        <RadioValue
                           name="activation"
                           items={filteredAmountType}
                           value={Number(selectedActivation)}
                           onChange={setSelectedActivation}
                           disabled={selectedTrailing === false || isUpdateMode}
                        />
                     </div>
                  </div>
               </li>
               <hr className="border-[#000000]" />
               <li className="grid grid-cols-3 li_style before:top-1/2">
                  <div className="flex-initial">
                     <label className="label_style">
                        Activation (Refer (A) Selection)
                     </label>
                     <input
                        className={`input_style ${
                           errors.activation ? "input_style-error" : ""
                        }`}
                        placeholder="2%"
                        value={activationValue || ""}
                        onChange={(e) => {
                           setActivationValue(e.target.value);
                           if (e.target.value) {
                              setErrors((prevErrors) => ({
                                 ...prevErrors,
                                 activation: null,
                              }));
                           }
                        }}
                        disabled={
                           selectedTrailing === false ||
                           selectedActivation === 1 ||
                           activationSelection !== "" ||
                           isUpdateMode
                        }
                     />
                     <span className="note_span">
                        Activated by entry price distance. (%){" "}
                     </span>
                  </div>
                  <div className="md:text-sm text-[10px] absolute left-1/3 transform -translate-x-2 md:-translate-x-5 -translate-y-1/6 top-1/2 text-gray-500">
                     or
                  </div>
                  <div className="flex-initial">
                     <label className="label_style">
                        Activation (Refer (A) Selection)
                     </label>
                     <input
                        className={`input_style ${
                           errors.activation ? "input_style-error" : ""
                        }`}
                        placeholder="1000"
                        value={activationSelection || ""}
                        onChange={(e) => {
                           setActivationSelection(e.target.value);
                           if (e.target.value) {
                              setErrors((prevErrors) => ({
                                 ...prevErrors,
                                 activation: null,
                              }));
                           }
                        }}
                        disabled={
                           selectedTrailing === false ||
                           selectedActivation === 3 ||
                           activationValue !== "" ||
                           isUpdateMode
                        }
                     />
                     <span className="note_span">
                        Activated by entry price distance. ($){" "}
                     </span>
                  </div>
               </li>
               <li className="grid grid-cols-3 li_style before:top-1/2">
                  <div className="flex-initial mb-2">
                     <label className="label_style">Position Close</label>
                     <input
                        type="number"
                        className={`input_style ${
                           errors.positionClose ? "input_style-error" : ""
                        }`}
                        value={positionCloseAmount || ""}
                        onChange={(e) => {
                           const value = Number(e.target.value);
                           setPositionCloseAmount(value);
                           if (
                              value >= 0.01 &&
                              value <= 10 &&
                              selectedTrailing &&
                              selectedActivation !== 1
                           ) {
                              setErrors((prevErrors) => ({
                                 ...prevErrors,
                                 positionClose: null,
                              }));
                           }
                        }}
                        min="0.01"
                        max="10"
                        step="0.01"
                        disabled={
                           selectedTrailing === false ||
                           positionCloseQty !== "" ||
                           selectedActivation === 1 ||
                           isUpdateMode
                        }
                     />
                     {errors.positionClose && (
                        <p className="absolute text-red-400 text-[10px]">
                           {errors.positionClose}
                        </p>
                     )}
                  </div>
                  <div className="md:text-sm text-[10px] absolute left-1/3 transform -translate-x-2 md:-translate-x-5 -translate-y-1/6 top-1/2 text-gray-500">
                     or
                  </div>
                  <div className="flex-initial mb-2 flex items-end">
                     <input
                        className={`input_style ${
                           errors.positionClose ? "input_style-error" : ""
                        }`}
                        value={positionCloseQty || ""}
                        onChange={(e) => {
                           setPositionCloseQty(e.target.value);
                           if (e.target.value) {
                              setErrors((prevErrors) => ({
                                 ...prevErrors,
                                 positionClose: null,
                              }));
                           }
                        }}
                        disabled={
                           selectedTrailing === false ||
                           positionCloseAmount !== "" ||
                           selectedActivation === 3 ||
                           isUpdateMode
                        }
                     />
                  </div>
               </li>
               <span className="md:text-[11px] md:ml-10 ml-6 text-[6px] xl:ml-32 text-btn-red font-semibold">
                  Position closes after trailing stop hits trailing price. ($)
               </span>
               <div>
                  <span className="md:text-base text-[7px] text-gray-700 block mb-1 md:ml-2 font-semibold">
                     Invest amount - Varies by Group
                  </span>
                  {customerGroupOptions.map((group, index) => (
                     <li
                        key={group.value}
                        className="grid grid-cols-3 items-end gap-4 w-full mb-4"
                     >
                        <div className="flex items-center">
                           <CheckBox
                              name="customerGroup"
                              items={[group]}
                              selectedValues={selectedGroups}
                              onChange={() => handleCheckboxChange(group.value)}
                              disabled={
                                 isUpdateMode ||
                                 (index === customerGroupOptions.length - 1 &&
                                    !isSecondGroupSelected)
                              }
                           />
                        </div>
                        <div className="flex-initial items-center">
                           <label className="label_style">Invest Amount</label>
                           <input
                              className={`input_style w-full ${
                                 errors[group.value] ? "input_style-error" : ""
                              }`}
                              placeholder="$"
                              value={
                                 investmentValues[group.value]?.dollar || ""
                              }
                              onChange={(e) =>
                                 handleInvestmentChange(
                                    group.value,
                                    "dollar",
                                    e.target.value
                                 )
                              }
                              disabled={
                                 isInputDisabled(group.value) ||
                                 investmentValues[group.value]?.qty ||
                                 isUpdateMode
                              }
                           />
                        </div>

                        <div className="flex items-center">
                           {index === customerGroupOptions.length - 1 ? (
                              <div className="w-full">
                                 <button
                                    type="button"
                                    disabled={
                                       isInputDisabled(
                                          CustomerGroup.Exclusive
                                       ) || isUpdateMode
                                    }
                                    onClick={() =>
                                       setDropdownVisible((prev) => !prev)
                                    }
                                    className="input_style relative justify-between flex items-center gap-2"
                                 >
                                    <span
                                       className={`${
                                          selectedCustomer
                                             ? "opacity-100"
                                             : "opacity-0"
                                       }`}
                                    >
                                       {selectedCustomer?.label}
                                    </span>
                                    <svg
                                       xmlns="http://www.w3.org/2000/svg"
                                       fill="none"
                                       viewBox="0 0 24 24"
                                       strokeWidth={1.5}
                                       stroke="currentColor"
                                       className="size-5"
                                    >
                                       <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="m19.5 8.25-7.5 7.5-7.5-7.5"
                                       />
                                    </svg>
                                 </button>

                                 {dropdownVisible && (
                                    <SearchDropDown
                                       options={dropdownOptions}
                                       onSelectCustomer={handleSelectCustomer}
                                       onClose={() => setDropdownVisible(false)}
                                       searchValue={searchValue}
                                       setSearchValue={setSearchValue}
                                    />
                                 )}
                                 {errors.searchDropdown && (
                                    <p className="absolute text-red-500 text-[10px]">
                                       {errors.searchDropdown}
                                    </p>
                                 )}
                              </div>
                           ) : (
                              <input
                                 className={`input_style w-full ${
                                    errors[group.value]
                                       ? "input_style-error"
                                       : ""
                                 }`}
                                 placeholder="Qty"
                                 value={
                                    investmentValues[group.value]?.qty || ""
                                 }
                                 onChange={(e) =>
                                    handleInvestmentChange(
                                       group.value,
                                       "qty",
                                       e.target.value
                                    )
                                 }
                                 disabled={
                                    isInputDisabled(group.value) ||
                                    investmentValues[group.value]?.dollar ||
                                    isUpdateMode
                                 }
                              />
                           )}
                        </div>
                     </li>
                  ))}
               </div>
            </ul>
            {errors.groups && (
               <p className="text-red-500 text-sm">{errors.groups}</p>
            )}
            <div className="flex justify-end my-10 mx-5">
               <button
                  onClick={handleSubmit}
                  type="submit"
                  className="bg-btn-green hover:bg-[#37bd54] font-semibold text-[10px] md:text-base md:py-3.5 md:px-4 py-1.5 px-2 rounded-full w-1/3 md:w-1/3 xl-1/4 disabled:bg-mc_light_grey mt-10"
                  disabled={isFormDisabled || isUpdateMode}
               >
                  Save Settings
               </button>
            </div>
         </form>
         <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            message={modalMessage}
            type={modalType}
         />
      </div>
   );
};

export default Settings;
