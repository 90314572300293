import React from "react";
import Status from "../../../../static/enums/statusEnum";
import { PlatformType } from "../../../../static/enums/platformTypeEnum";

const CustomersRows = ({ item, index }) => {
   const getPlatformNameById = (id) => {
      const platform = PlatformType.find((platform) => platform.id === id);
      return platform ? platform.name : "Unknown";
   };

   return (
      <div
         className={`flex items-center md:px-6 md:py-4 px-1 py-2 border md:my-4 my-2 border-grey-light rounded-full space-x-5`}
      >
         <span className="tr_style w-1/8 text-gray-600 font-semibold">
            {index + 1}
         </span>
         <span className="tr_style w-1/8">
            {item.account ? (
               <>
                  {item.account.firstName} <br /> {item.account.lastName}
               </>
            ) : (
               "-"
            )}
         </span>
         <span className="tr_style w-1/8">{item.id}</span>
         <span className="tr_style w-1/8">
            {item.account.country?.name || "-"}
         </span>
         <span className="tr_style w-1/8">
            <span className="flex items-center w-full">
               <span
                  className={`md:py-1.5 py-0.5 md:text-xs text-[5px] self-center font-semibold rounded-full flex-grow text-center ${
                     Number(item.account.status) === 3
                        ? "bg-[#5DF888] text-black"
                        : Number(item.account.status) === 2
                        ? "bg-[#FF4949] text-white"
                        : "bg-[#716dc0]"
                  }`}
               >
                  {Status.find(
                     (status) => status.value === Number(item.account.status)
                  )?.label || "Suspended"}
               </span>
            </span>
         </span>
         <span className="tr_style w-1/8">{getPlatformNameById(item.platformType)}</span>
         <span className="tr_style w-1/8">
            {item.account.datetimeInserted
               ? (() => {
                    const [datePart] = item.account.datetimeInserted.split("T");
                    const [year, month, day] = datePart.split("-");

                    const formattedDate = `${day}/${month}/${year}`;

                    return <>{formattedDate}</>;
                 })()
               : "-"}
         </span>
         <span
            className={`tr_style w-1/8 ${
               item?.currentWalletAmount > 0 ? "text-black" : "text-red-500"
            }`}
         >
            {item?.currentWalletAmount}
         </span>
      </div>
   );
};

export default CustomersRows;
