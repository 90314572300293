import React, { useEffect, useState } from "react";
import { CustomerGroup } from "../../../static/enums/customerGroupEnum";
import ToggleSwitch from "../../additional/ToggleSwitch";
import usePageTitle from "../../../hooks/usePageTitle";
import Cookies from "js-cookie";
import { changeReceiveOnly, getReceiveOnlyData } from "../../../api/webhookApi";
import LoadingSkeletonReceive from "./LoadingSceletonReceive";

const SettingsList = () => {
   const [receiveOnly, setReceiveOnly] = useState([]);
   const [loading, setLoading] = useState(true);

   usePageTitle("Webhook Settings");

   const formatDateTime = (isoString) => {
      const date = new Date(isoString);
      const datePart = date.toLocaleDateString("en-GB", {
         month: "2-digit",
         day: "2-digit",
         year: "numeric",
      });
      const timePart = date.toLocaleTimeString("en-GB", {
         hour: "numeric",
         minute: "numeric",
         hour12: true,
      });
      return `${datePart}\n${timePart}`;
   };

   useEffect(() => {
      const fetchData = async () => {
         const token = Cookies.get("accessToken");
         try {
            const data = await getReceiveOnlyData(token, 0, 5);
            if (data && data.data) {
               const transformedData = data.data.map((item) => ({
                  id: item.id,
                  group: item.group,
                  isActive: item.isActive,
                  lastUpdated: formatDateTime(item.datetimeUpdated),
               }));
               setReceiveOnly(transformedData);
            } else {
               console.error("Unexpected data format:", data);
            }
         } catch (error) {
            console.error("Error fetching data:", error);
         } finally {
            setLoading(false);
         }
      };

      fetchData();
   }, []);

   const getGroupName = (groupValue) => {
      return (
         Object.keys(CustomerGroup).find(
            (key) => CustomerGroup[key] === groupValue
         ) || "Unknown Group"
      );
   };

   const handleChangeReceiveOnly = async (itemId, currentValue) => {
      const token = Cookies.get("accessToken");
      try {
         const updatedItem = await changeReceiveOnly(
            itemId,
            token,
            !currentValue
         );
         setReceiveOnly((prevData) =>
            prevData.map((data) =>
               data.id === itemId
                  ? {
                       ...data,
                       isActive: updatedItem.isActive,
                       lastUpdated: formatDateTime(updatedItem.datetimeUpdated),
                    }
                  : data
            )
         );
      } catch (error) {
         console.error("Error updating item:", error);
      }
   };

   return (
      <div className="bg-grey-light md:p-16 p-4 min-h-screen">
         <h1 className="text-purple-circle font-bold md:text-3xl text-base text-center md:text-left md:ml-20 md:mb-10 mb-5">
            Receive Webhook Once
         </h1>
         <div className="bg-white py-4 w-2/3 rounded-lg px-10">
            {/* Headers */}
            <div className="grid grid-cols-3 items-center gap-4 p-4 font-bold text-gray-600 px-5">
               <div className="col-span-2 text-start">Group</div>
               <div className="col-span-1 text-center">Last Refresh</div>
            </div>
            {/* Data */}
            {loading ? (
               <LoadingSkeletonReceive itemsPerPage={4} />
            ) : (
               receiveOnly.map((item, index) => (
                  <div
                     key={index}
                     className={`grid grid-cols-3 items-center text-sm gap-4 py-2 border rounded-full mb-3 px-5`}
                  >
                     <div className="col-span-2 text-gray-700 font-semibold flex items-center justify-between pr-5">
                        {getGroupName(item.group)}
                        <ToggleSwitch
                           enabled={item.isActive}
                           setEnabled={() =>
                              handleChangeReceiveOnly(item.id, item.isActive)
                           }
                           disabled={false}
                        />
                     </div>
                     <div className="col-span-1 flex items-center gap-4 justify-center">
                        <p className="whitespace-pre-wrap text-center">
                           {item.lastUpdated}
                        </p>
                     </div>
                  </div>
               ))
            )}
         </div>
      </div>
   );
};

export default SettingsList;
