import React, { useState, useEffect, useMemo } from "react";
import DropdownSort from "../../../shared/DropDownSort";
import ItemsPerPageDropdown from "../../../shared/ItemsPerPageDropdown";
import PaginationControls from "../../../shared/PaginationControls";
import { useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import usePageTitle from "../../../../hooks/usePageTitle";
import Status from "../../../../static/enums/statusEnum";
import { getAllCountries } from "../../../../api/countryApi";
import CustomersHead from "./CustomersHeader";
import LoadingSkeletonAgentPages from "./LoadingSkeletonAgentPages";
import CustomersRows from "./CustomersRows";
import {
   getOwnCustomers,
   getStatisticsOwnCustomers,
   searchCustomersByAgent,
} from "../../../../api/agentApi";
import { PlatformType } from "../../../../static/enums/platformTypeEnum";

const Customers = () => {
   usePageTitle("Customer List");

   const [customers, setCustomers] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [isLoading, setIsLoading] = useState(true);
   const [itemsPerPage, setItemsPerPage] = useState(50);
   const [totalItems, setTotalItems] = useState(0);
   const [searchParams, setSearchParams] = useSearchParams();
   const [selectedCountry, setSelectedCountry] = useState(null);
   const [countryOptions, setCountryOptions] = useState([]);
   const [selectedStatus, setSelectedStatus] = useState(null);
   const [statistic, setStatistic] = useState(null);
   const [selectedMonth, setSelectedMonth] = useState("");
   const [sortType, setSortType] = useState(0);
   const [searchText, setSearchText] = useState("");
   const [selectedPlatform, setSelectedPlatform] = useState(null);

   useEffect(() => {
      const pageIndex = parseInt(searchParams.get("pageIndex"), 10) || 1;
      const country = searchParams.get("country");
      const status = searchParams.get("status");
      const month = searchParams.get("month");
      const platformType = searchParams.get("platformType");

      setCurrentPage(pageIndex);

      if (country) {
         const foundCountry = countryOptions.find(
            (option) => option.value === country
         );
         setSelectedCountry(
            foundCountry ? foundCountry : { value: country, label: country }
         );
      } else {
         setSelectedCountry(null);
      }

      if (status) {
         const foundStatus = Status.find(
            (option) => option.value === parseInt(status, 10)
         );
         setSelectedStatus(
            foundStatus ? foundStatus : { value: status, label: status }
         );
      } else {
         setSelectedStatus(null);
      }

      const getPlatformById = (id) => {
         return PlatformType.find(
            (platform) => platform.id === parseInt(id, 10)
         );
      };

      if (platformType) {
         const foundPlatform = getPlatformById(platformType);

         setSelectedPlatform(
            foundPlatform
               ? { value: foundPlatform.id, label: foundPlatform.name }
               : { value: platformType, label: platformType }
         );
      } else {
         setSelectedPlatform(null);
      }

      if (month) {
         const foundMonth = monthOptions.find(
            (option) => option.value === month
         );
         setSelectedMonth(
            foundMonth
               ? foundMonth
               : { value: foundMonth.value, label: foundMonth.label }
         );
      } else {
         setSelectedMonth("");
      }
   }, [searchParams, countryOptions]);

   const getMonthYearString = (monthYear) => {
      const [month, year] = monthYear.split(/(\d{4})/).filter(Boolean);
      const monthIndex =
         new Date(Date.parse(month + " 1, 2020")).getMonth() + 1;
      return `${year}-${monthIndex.toString().padStart(2, "0")}`;
   };

   const generateMonthsForRange = () => {
      const minDate = new Date(2024, 4, 1);
      const maxDate = new Date();

      const startYear = minDate.getFullYear();
      const endYear = maxDate.getFullYear();

      let allMonths = [{ value: "all", label: "All month" }];

      for (let year = endYear; year >= startYear; year--) {
         for (let month = 11; month >= 0; month--) {
            const date = new Date(year, month);

            if (year === 2024 && month < 4) continue;

            if (year === endYear && month > maxDate.getMonth()) continue;

            allMonths.push({
               value: `${date.toLocaleString("en-GB", {
                  month: "short",
               })}${year}`,
               label: `${date.toLocaleString("en-GB", {
                  month: "short",
               })} ${year}`,
            });
         }
      }

      return allMonths;
   };
   const monthOptions = generateMonthsForRange();

   useEffect(() => {
      const fetchData = async () => {
         setIsLoading(true);
         const token = Cookies.get("accessToken");
         if (!token) {
            console.error("No token found");
            return;
         }

         const filters = {};

         if (selectedStatus?.value && selectedStatus.value !== "all") {
            filters.statuses = [selectedStatus.value];
         }
         if (selectedCountry?.value && selectedCountry.value !== "all") {
            filters.countryIds = [selectedCountry.value];
         }
         if (selectedPlatform?.value && selectedPlatform.value !== "all") {
          filters.platformTypes = [selectedPlatform.value];
       }
         if (selectedMonth?.value && selectedMonth.value !== "all") {
            filters.datetimesInserted = [
               getMonthYearString(selectedMonth.value),
            ];
         }

         let sorts = {};

         if (sortType === 1 || sortType === 2) {
            sorts = {
               name: "currentWalletAmount",
               type: sortType,
            };
         }

         try {
            let data;

            if (searchText) {
               data = await searchCustomersByAgent(
                  currentPage - 1,
                  itemsPerPage,
                  token,
                  searchText
               );
            } else {
               data = await getOwnCustomers(
                  currentPage - 1,
                  itemsPerPage,
                  token,
                  filters,
                  sorts
               );
            }

            if (data.successful) {
               setCustomers(data.data.data || []);
               setTotalItems(data.data.paging.totalCount || 0);
            } else {
               console.error("Error fetching data:", data.error.message);
            }
         } catch (error) {
            console.error("Error fetching data:", error.message);
         } finally {
            setIsLoading(false);
         }
      };
      const timer = setTimeout(
         () => {
            fetchData();
         },
         searchText ? 1000 : 0
      );

      return () => clearTimeout(timer);
   }, [
      currentPage,
      itemsPerPage,
      selectedCountry,
      selectedStatus,
      selectedMonth,
      sortType,
      searchText,
      selectedPlatform
   ]);

   useEffect(() => {
      const fetchStatistics = async () => {
         const token = Cookies.get("accessToken");
         try {
            const result = await getStatisticsOwnCustomers(token);
            if (result && result.successful) {
               setStatistic({
                  active: result.data.active,
                  blocked: result.data.blocked,
               });
            } else {
               console.error("Error fetching symbols:", result.error.message);
            }
         } catch (error) {
            console.error("Error fetching symbols:", error);
         }
      };

      fetchStatistics();
   }, []);

   useEffect(() => {
      const fetchCountries = async () => {
         try {
            const response = await getAllCountries();
            const countries = response.map((country) => ({
               value: country.id,
               label: country.name,
            }));
            setCountryOptions([
               { value: "all", label: "All Countries" },
               ...countries,
            ]);
         } catch (error) {
            console.error("Error fetching countries:", error);
         }
      };

      fetchCountries();
   }, []);

   const totalPages = Math.ceil(totalItems / itemsPerPage);

   const filteredStatus = useMemo(
      () => [
         { value: "all", label: "All Statuses" },
         ...Status.filter((status) => [3, 2].includes(status.value)),
      ],
      []
   );

   const platformOptions = useMemo(
       () => [
         { value: "all", label: "All Platforms" },
         ...PlatformType.map(({ name, id }) => ({
           value: id,
           label: name,
         })),
       ],
       []
     );
     
   
      useEffect(() => {
         if (selectedPlatform) {
            if (selectedPlatform.value === "all") {
               if (selectedPlatform.label !== "All Platforms") {
                  setSelectedPlatform({ value: "all", label: "All Platforms" });
               }
            } else {
               const selectedOption = platformOptions.find(
                 (option) => option.value === selectedPlatform.value
               );
   
               if (
                  selectedOption &&
                  selectedPlatform.label !== selectedOption.label
               ) {
                  setSelectedPlatform(selectedOption);
               }
            }
         }
      }, [selectedPlatform, platformOptions]);

   useEffect(() => {
      if (selectedStatus) {
         const selectedOption = filteredStatus.find(
            (option) => option.value === selectedStatus.value
         );
         if (selectedOption) {
            setSelectedStatus(selectedOption);
         }
      }
   }, [selectedStatus, filteredStatus]);

   const currentData = customers;

   const hasRecords = currentData?.length > 0;

   const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
      setSearchParams({
         pageIndex: newPage,
         country: selectedCountry?.value || "",
         status: selectedStatus?.value || "",
         month: selectedMonth?.value || "",
         platformType: selectedPlatform?.value || "",
      });
   };

   const handleMonthChange = (selectedOption) => {
      setSelectedMonth(selectedOption);
      console.log(selectedMonth);
      setSearchParams({
         pageIndex: 1,
         month: selectedOption?.value || "",
         country: selectedCountry?.value || "",
         status: selectedStatus?.value || "",
         platformType: selectedPlatform?.value || "",
      });
      setCurrentPage(1);
   };

   const handleSortByWallet = () => {
      setSortType((prevSortType) => (prevSortType + 1) % 3);
      console.log(sortType);
      setCurrentPage(1);
   };

   const handleItemsPerPageChange = (event) => {
      const newPageSize = Number(event.target.value);
      setItemsPerPage(newPageSize);
      setCurrentPage(1);
   };

   const handleCountryChange = (selectedOption) => {
      setSelectedCountry(selectedOption);
      setSearchParams({
         pageIndex: 1,
         country: selectedOption?.value || "",
         status: selectedStatus?.value || "",
         month: selectedMonth?.value || "",
         platformType: selectedPlatform?.value || "",
      });
      setCurrentPage(1);
   };

   const handlePlatformChange = (selectedOption) => {
    setSelectedPlatform(selectedOption);
    setSearchParams({
       pageIndex: 1,
       country: selectedCountry?.value || "",
       status: selectedStatus?.value || "",
       month: selectedMonth?.value || "",
       platformType: selectedOption?.value || "",
    });
    setCurrentPage(1);
 };

   const handleStatusChange = (selectedOption) => {
      setSelectedStatus(selectedOption);
      setSearchParams({
         pageIndex: 1,
         country: selectedCountry?.value || "",
         status: selectedOption?.value || "",
         month: selectedMonth?.value || "",
         platformType: selectedPlatform?.value || "",
      });
      setCurrentPage(1);
   };

   const handleResetFilters = () => {
      setSelectedCountry(null);
      setSelectedStatus(null);
      setSelectedMonth(null);
      setSelectedPlatform(null);
      setSearchParams({});
      setCurrentPage(1);
      setSortType(0);
      setSearchText("");
   };
   return (
      <div className="bg-grey-light md:p-16 p-4 min-h-screen">
         <h1 className="text-purple-circle font-bold md:text-3xl text-base text-center md:text-left md:ml-20 md:mb-10 mb-5">
            Customer Listing
         </h1>
         <div className="flex-col justify-end items-center space-y-5 py-2 px-1 xl:px-12">
            <div className="flex justify-end md:space-x-5 w-full space-x-1 items-center">
               <div className="relative">
                  <input
                     className="pl-2 pr-8 py-1 outline-none rounded-md border border-gray-400 bg-[#F3F5F7] w-full"
                     type="text"
                     value={searchText}
                     onChange={(e) => setSearchText(e.target.value)}
                  />
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24"
                     strokeWidth={1.5}
                     stroke="currentColor"
                     className="absolute top-1/2 right-3 w-5 h-5 transform -translate-y-1/2 text-gray-500"
                  >
                     <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                     />
                  </svg>
               </div>
               <span className="font-semibold md:text-xs text-[6px]">
                  Active({statistic?.active})
               </span>
               <span className="font-semibold md:text-xs text-[6px]">
                  Blocked({statistic?.blocked})
               </span>
               <div className="xl:w-1/8">
                  <DropdownSort
                     options={filteredStatus}
                     selected={selectedStatus}
                     onChange={handleStatusChange}
                     title="Status"
                  />
               </div>
               <div className="xl:w-1/8">
                  <DropdownSort
                     options={countryOptions}
                     selected={selectedCountry}
                     onChange={handleCountryChange}
                     title="Country"
                  />
               </div>
               <div className="xl:w-1/8">
                  <DropdownSort
                     options={monthOptions}
                     selected={selectedMonth}
                     onChange={handleMonthChange}
                     title={"M.Since"}
                  />
               </div>
               <div className="xl:w-1/8">
                  <DropdownSort
                     options={platformOptions}
                     selected={selectedPlatform}
                     onChange={handlePlatformChange}
                     title="Platform"
                  />
               </div>
               <button onClick={handleSortByWallet}>
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24"
                     strokeWidth={1.5}
                     stroke="currentColor"
                     className="size-8 p-2 text-white rounded-full bg-[#4C009B]"
                  >
                     <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                     />
                  </svg>
               </button>
               <button onClick={handleResetFilters}>
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24"
                     strokeWidth={1.5}
                     stroke="currentColor"
                     className="md:size-6 size-3"
                  >
                     <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
                     />
                  </svg>
               </button>
            </div>
         </div>
         <div className="flex flex-col justify-center items-center gap-8">
            <div className="w-full items-start bg-[#ffffff] md:py-10 py-2 rounded-lg md:px-12 px-1.5">
               <CustomersHead />
               {isLoading ? (
                  <LoadingSkeletonAgentPages itemsPerPage={itemsPerPage} />
               ) : hasRecords ? (
                  currentData.map((item, index) => (
                     <CustomersRows
                        key={item.id}
                        item={item}
                        index={(currentPage - 1) * itemsPerPage + index}
                     />
                  ))
               ) : (
                  <div className="text-center pt-10">No records available</div>
               )}
               <div className="flex justify-end space-x-5">
                  <ItemsPerPageDropdown
                     itemsPerPage={itemsPerPage}
                     handleItemsPerPageChange={handleItemsPerPageChange}
                     totalItems={totalItems}
                  />
                  <PaginationControls
                     currentPage={currentPage}
                     totalPages={totalPages}
                     handlePageChange={handlePageChange}
                     itemsPerPage={itemsPerPage}
                     totalItems={totalItems}
                  />
               </div>
            </div>
         </div>
      </div>
   );
};

export default Customers;
