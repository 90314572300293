import React from "react";
import { useNavigate } from "react-router-dom";
import { StrategyStatus } from "../../../static/enums/strategiesEnums";
import { AccountType } from "../../../static/enums/accountTypeEmum";

const StrategyTableRow = ({ item, index, isSelected, handleRowSelect, accountType }) => {
  const statusLabel =
    StrategyStatus.find((status) => status.value === item.status)?.label ||
    "Unknown";

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/strategy/${item.id}`);
  };
  return (
      <div
          className={`flex justify-between items-center md:px-6 md:py-4 px-1 py-2 md:my-4 my-2 border border-grey-light rounded-full ${
              isSelected ? 'bg-gray-100' : ''
          }`}
      >
          <span className='tr_style w-1/7 text-gray-600 font-semibold'>
              {index + 1}
          </span>
          <span className='tr_style w-1/7'>{item.symbol}</span>
          <span className='tr_style w-1/7'>{item.id}</span>
          <span className='tr_style w-1/7 flex items-center'>
              <span
                  className={`md:py-1.5 py-1 md:mx-4 mx-1 font-semibold rounded-full flex-grow text-center ${
                      item.status === 1
                          ? 'bg-[#5DF888]'
                          : 'bg-[#FF4949] text-white'
                  }`}
              >
                  {statusLabel}
              </span>
          </span>
          <span className='tr_style w-1/7'>
              {item.win}/{item.loss}
          </span>
          <span className='tr_style w-1/7'>
              {item.datetimeInserted
                  ? (() => {
                        const [datePart] = item.datetimeInserted.split('T');
                        const [year, month, day] = datePart.split('-');

                        const formattedDate = `${day}/${month}/${year}`;

                        return <>{formattedDate}</>;
                    })()
                  : '-'}
          </span>
          <span className='tr_style w-1/7'>
              <button
                  className='text-purple-600 hover:text-purple-800'
                  onClick={handleClick}
              >
                  <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='md:w-5 w-2.5'
                  >
                      <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125'
                      />
                  </svg>
              </button>
          </span>
      </div>
  );
};

export default StrategyTableRow;
