import React, { useState, useEffect, useContext } from 'react';
import usePageTitle from '../../../hooks/usePageTitle';
import PaginationControls from '../../shared/PaginationControls';
import ItemsPerPageDropdown from '../../shared/ItemsPerPageDropdown';
import LoadingSkeletonEntry from './LoadingSkeletonEntry';
import EntryOrderHeader from './EntryOrderHeader';
import EntryOrderRow from './EntryOrderRow';
import Cookies from 'js-cookie';
import { getErrorsData, getStatisticsError, uploadEntry } from '../../../api/EntryApi';
import DropdownSort from '../../shared/DropDownSort';
import { useSearchParams } from 'react-router-dom';
import { ErrorContext } from '../../ErrorProvider';
import Modal from '../../shared/Modal';
import { base64ToBytes } from '../../../utils/Convertation';
import { getAllTransactionIds } from '../../../api/orderApi';

const EntryOrderList = () => {
  usePageTitle('Entry Audit');

  const { setHasErrorType1 } = useContext(ErrorContext);
	setHasErrorType1(false);

  const [entryOrders, setEntryOrders] = useState([]);
  const [errorAmount, setErrorAmount] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedCurrent, setSelectedCurrent] = useState({ value: 'current', label: 'Current' })
  const [searchParams, setSearchParams] = useSearchParams({

});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("success");
  //{value: 'last', label: 'Last'},
  const defaultCurrentTotalOptions=[{value: 'current', label:'Today'},{ value: 'all', label: 'All' }]

const [currentTotalOptions, setCurrentTotalOptions]=useState(defaultCurrentTotalOptions)



useEffect(() => {
  const pageIndex = parseInt(searchParams.get('pageIndex'), 10) || 1;
  const current = searchParams.get('current') || 'current';
  const month = searchParams.get('month');

  setCurrentPage(pageIndex);

  if (!searchParams.has('current')) {
    setSearchParams((prevParams) => ({
      ...Object.fromEntries(prevParams),
      current: 'current',
    }));
  }

  const foundCurrent = currentTotalOptions.find((option) => option.value === current);
  setSelectedCurrent(
    foundCurrent || { value: 'current', label: 'Today' }
  );

  if (month) {
    const foundMonth = monthOptions.find((option) => option.value === month);
    setSelectedMonth(
      foundMonth || { value: month, label: month }
    );
  } else {
    setSelectedMonth('');
  }
}, [searchParams, currentTotalOptions]);





  const getMonthYearString = (monthYear) => {
    const [month, year] = monthYear.split(/(\d{4})/).filter(Boolean);
    const monthIndex = new Date(Date.parse(month + ' 1, 2020')).getMonth() + 1; 
    return `${year}-${monthIndex.toString().padStart(2, '0')}`; 
  };

  const generateMonthsForRange = () => {
    const minDate = new Date(2024, 4, 1);
    const maxDate = new Date();

    const startYear = minDate.getFullYear();
    const endYear = maxDate.getFullYear();

    let allMonths = [{ value: 'all', label: 'All month' }];

    for (let year = endYear; year >= startYear; year--) {
      for (let month = 11; month >= 0; month--) {
        const date = new Date(year, month);

        if (year === 2024 && month < 4) continue;

        if (year === endYear && month > maxDate.getMonth()) continue;

        allMonths.push({
          value: `${date.toLocaleString('en-GB', { month: 'short' })}${year}`,
          label: `${date.toLocaleString('en-GB', { month: 'short' })} ${year}`,
        });
      }
    }

    return allMonths;
  };
  const monthOptions = generateMonthsForRange();


  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get('accessToken');
      setIsLoading(true);
      try {
        const filters = [];
        
        if (
          selectedCurrent.value &&
          !defaultCurrentTotalOptions.some((option) => option.value === selectedCurrent.value)
        ) {
          filters.push({
            name: 'transactionId',
            value: selectedCurrent.value,
          });
        }
        
        if (selectedMonth?.value && selectedMonth.value !== 'all') {
          filters.push({
            name: 'datetimeInserted',
            value: getMonthYearString(selectedMonth.value),
          });
        }
  
        const today = new Date();
        const toISOWithOffset = (date, offsetHours) => {
          const localDate = new Date(date.getTime() + offsetHours * 60 * 60 * 1000);
          const year = localDate.getFullYear();
          const month = String(localDate.getMonth() + 1).padStart(2, '0');
          const day = String(localDate.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
        };
        
        const offsetHours = 8;
        let startDate = null;
        let endDate = toISOWithOffset(today, offsetHours);
        
        if (selectedCurrent?.value === 'last') {
          const yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);
          endDate = toISOWithOffset(yesterday, offsetHours);
        } else if (selectedCurrent?.value === 'current') {
          startDate = endDate;
        }
        const data = await getErrorsData(
          token,
          currentPage - 1,
          itemsPerPage,
          filters,
          endDate,
          startDate
        );
  
        if (data) {
          setEntryOrders(data.data);
          setTotalItems(data.paging.totalCount);
        } else {
          console.error('Server Error:', data.error.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [itemsPerPage, currentPage, selectedMonth, selectedCurrent]);
  

  useEffect(() => {
    const statisticsError = async () => {
      const token = Cookies.get('accessToken');
      if (!token) {
        console.error('No token found');
        return;
      }
      try {
        const data = await getStatisticsError(token);
        setErrorAmount(data);
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    };
    statisticsError();
  }, []);

  useEffect(() => {
    const fetchTransactionIds = async () => {
      const token = Cookies.get('accessToken');
      try {
        const result = await getAllTransactionIds(token);
        if (result && result.successful) {
          const transactionIds = result.data.transaction_ids;
  
          setCurrentTotalOptions((prevOptions) => [
            ...prevOptions,
            ...transactionIds.map((name) => ({ value: name, label: name })),
          ]);
        } else {
          console.error('Error fetching transactionIds:', result.error.message);
        }
      } catch (error) {
        console.error('Error fetching transactionIds:', error);
      }
    };
  
    fetchTransactionIds();
  }, []);
  

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const hasRecords = entryOrders?.length > 0;

  const handleRowSelect = (index) => {
    const pageIndex = (currentPage - 1) * itemsPerPage + index;
    if (selectedRows.includes(pageIndex)) {
      setSelectedRows(selectedRows.filter((i) => i !== pageIndex));
    } else {
      setSelectedRows([...selectedRows, pageIndex]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAll(false);
    } else {
      const allIndexes = entryOrders.map(
        (_, index) => (currentPage - 1) * itemsPerPage + index
      );
      setSelectedRows(allIndexes);
      setSelectAll(true);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setSearchParams({
      pageIndex: newPage,
      month: selectedMonth?.value || '',
      current: selectedCurrent?.value || ''
    });
    setSelectAll(false);
  };

  const handleItemsPerPageChange = (event) => {
    const newPageSize = Number(event.target.value);
    setItemsPerPage(newPageSize);
    setCurrentPage(1);
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
    setSearchParams({
      pageIndex: 1,
      month: selectedOption?.value || '',
      current: 'all',
    });
    setSelectedCurrent({ value: 'all', label: 'All' })
    setCurrentPage(1);
  };

  const handleCurrentChange = (selectedOption) => {
    setSelectedCurrent(selectedOption);
    setSelectedMonth('')
    setSearchParams({
      pageIndex: 1,
      month: '',
      current: selectedOption?.value || ''
    });

    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setSelectedMonth(null);
    setSelectedCurrent({ value: 'current', label: 'Current' })
    setCurrentPage(1);
    setSearchParams({});
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };


  const handleExport = async () => {
    try {
      const token = Cookies.get('accessToken');
      if (!token) {
        console.error('No token found');
        return;
      }
      const result = await uploadEntry(token);
      if (result.successful) {
        const fileBase64String = result.data?.fileBase64String;
  
        if (fileBase64String) {
          const byteArray = base64ToBytes(fileBase64String);
          
          if (byteArray) {
            const blob = new Blob([byteArray], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'entry.xlsx'; 
            a.click();
            URL.revokeObjectURL(url); 
            setIsModalOpen(true)
            setModalType('success')
            setModalMessage('File is download successfully')
          } else {
            setIsModalOpen(true)
            setModalType('error')
            setModalMessage('File is not download')
          }
        } else {
          setIsModalOpen(true)
          setModalType('error')
          setModalMessage('File is not download')
        }
      } else {
        setIsModalOpen(true)
        setModalType('error')
        setModalMessage('File is not download')
      }
    } catch (error) {
      setIsModalOpen(true)
            setModalType('error')
            setModalMessage('File is not download')
    }
  };

  return (
    <div className="bg-grey-light md:p-16 p-4 min-h-screen">
      <h1 className="text-purple-circle font-bold md:text-3xl text-base text-center md:text-left md:ml-20 md:mb-10 mb-5">
        Entry Order Audit
      </h1>
      <div className="flex justify-end items-center md:space-x-5 space-x-1 py-2 md:px-12 px-5">
      <button onClick={handleExport}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
              />
            </svg>
          </button>
        <span className="font-semibold md:text-sm text-[6px]">
          Total Position({errorAmount?.total})
        </span>
        <span className="font-semibold md:text-sm text-[6px]">
          Error case({errorAmount?.errors || '0'})
        </span>
        <div className="xl:w-1/7">
          <DropdownSort
            options={currentTotalOptions}
            selected={selectedCurrent}
            onChange={handleCurrentChange}
            title={'All'}
          />
        </div>
        <div className="xl:w-1/7">
          <DropdownSort
            options={monthOptions}
            selected={selectedMonth}
            onChange={handleMonthChange}
            title={'Month'}
          />
        </div>
        <button onClick={handleResetFilters}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="md:size-6 size-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
            />
          </svg>
        </button>
      </div>
      <div className="flex flex-col justify-center items-center gap-8">
        <div className="w-full items-start bg-[#ffffff] md:py-10 py-2 rounded-lg md:px-12 px-1.5">
          <EntryOrderHeader
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
          />
          {isLoading ? (
            <LoadingSkeletonEntry itemsPerPage={itemsPerPage} />
          ) : hasRecords ? (
            entryOrders.map((item, index) => (
              <EntryOrderRow
                key={item.id}
                item={item}
                index={(currentPage - 1) * itemsPerPage + index}
                isSelected={selectedRows.includes(
                  (currentPage - 1) * itemsPerPage + index
                )}
                handleRowSelect={handleRowSelect}
              />
            ))
          ) : (
            <div className="text-center pt-10">No records available</div>
          )}

          <div className="flex justify-end space-x-5">
            <ItemsPerPageDropdown
              itemsPerPage={itemsPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              totalItems={totalItems}
            />
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        message={modalMessage}
        type={modalType}
      />
    </div>
  );
};

export default EntryOrderList;
