import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EntryDirection } from '../../../static/enums/strategiesEnums';
import { ExitType } from '../../../static/enums/exitTypeEnum';

const ExitOrderRow = ({ item, index, isSelected }) => {
	console.log(item)
  const navigate = useNavigate();
  const orderTypeLabel =
    EntryDirection.find((type) => type.value === item.strategy.entryDirection)
      ?.label || '-';

  const exitTypeLabel =
    Object.keys(ExitType).find((key) => ExitType[key] === item.exitType) || '-';

  return (
    <div
      className={`flex items-center md:px-6 md:py-4 px-1 py-2 border md:my-4 my-2 border-grey-light rounded-full space-x-5 ${
        isSelected ? 'bg-gray-100' : ''
      }`}
    >
      <span className="tr_style w-1/8 text-gray-600 font-semibold">
        {index + 1}
      </span>
      <span
        className="tr_style w-1/8 cursor-pointer"
        onClick={() => navigate(`/customer/${item.customer.id}`)}
      >
        {item.customer.firstName} <br /> {item.customer.lastName}{' '}
      </span>
      {item.customer.agent ? (
        <a href={`/agent/${item.customer.agent.id}`} className="tr_style w-1/8">
          <span>
            {item.customer.agent.firstName ? (
              <>
                {item.customer.agent.firstName} <br />
                {item.customer.agent.lastName}
              </>
            ) : (
              '-'
            )}
          </span>
        </a>
      ) : (
        <span className="tr_style w-1/8">No agent</span>
      )}
      <span className="tr_style w-1/8">{item.transactionId}</span>
      <span className="tr_style w-1/8">{orderTypeLabel || '-'}</span>
      <span className="tr_style w-1/8">
      {item.datetimeInserted
  ? (() => {
      const [datePart, timePart] = item.datetimeInserted.split('T');
      const [year, month, day] = datePart.split('-');
      const [time] = timePart.split('+');
      const [hours, minutes] = time.split(':');

      const formattedDate = `${day}/${month}/${year}`;
      const formattedTime = `${hours}:${minutes}`;

      return (
        <>
          {formattedDate} <br /> {formattedTime}
        </>
      );
    })()
  : '-'}
      </span>
      <span className="tr_style w-1/8">{exitTypeLabel}</span>
      <span className="tr_style w-1/8">-</span>
      {/* {resultLabel} */}
    </div>
  );
};

export default ExitOrderRow;
