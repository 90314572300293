import React, { useMemo, useState, useEffect } from 'react';
import { CustomerGroup } from '../../static/enums/customerGroupEnum';
import { updateCustomerGroup } from '../../api/customerApi';
import Cookies from 'js-cookie';
import Modal from './Modal';

const ModalChangeGroup = ({ isVisible, onClose, onGroupChangeSuccess }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("success");

  const handleModalClose = () => {
    setIsModalOpen(false);
  };


  const customerGroupOptions = useMemo(() => {
    return Object.entries(CustomerGroup).map(([key, value]) => ({
      value,
      label: key,
    }));
  }, []);

  const [fromValue, setFromValue] = useState(
    customerGroupOptions[0]?.value);
  const [toValue, setToValue] = useState(customerGroupOptions[0]?.value);

  useEffect(() => {
    if (!isVisible) {
      setFromValue(customerGroupOptions[0]?.value);
      setToValue(customerGroupOptions[0]?.value);
    }
  }, [isVisible, customerGroupOptions]);

  if (!isVisible) return null;

  const handleBackgroundClick = (e) => {
    e.stopPropagation();
    onClose();
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handleSwap = () => {
    setFromValue(toValue);
    setToValue(fromValue);
  };

  const handleConfirm = async () => {
    try {
      const token = Cookies.get("accessToken");
      const result = await updateCustomerGroup(Number(fromValue), Number(toValue), token);
  
      if (result?.successful) {
        setModalMessage("Groups changed successfully!");
        setModalType("success");
        setIsModalOpen(true);
        onGroupChangeSuccess();
      } else {
        setModalMessage("Failed to change groups. Please try again.");
        setModalType("error");
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Request failed:", error.message);
      setModalMessage("An unexpected error occurred.");
      setModalType("error");
      setIsModalOpen(true);
    }
  };
  

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
      onClick={handleBackgroundClick}
    >
      <div
        className="bg-white p-16 rounded-md w-1/3"
        onClick={handleModalClick}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Convert</h2>
        </div>

        <hr className="border-gray-300 my-4" />

        <div className="flex items-center justify-between w-full space-x-4">
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              From
            </label>
            <select
              className="w-full px-3 py-2 rounded-md bg-gray-100"
              value={fromValue}
              onChange={(e) => setFromValue(e.target.value)}
            >
              {customerGroupOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <button className="self-end" onClick={handleSwap}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="size-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3"
              />
            </svg>
          </button>

          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              To
            </label>
            <select
              className="w-full px-3 py-2 rounded-md bg-gray-100"
              value={toValue}
              onChange={(e) => setToValue(e.target.value)}
            >
              {customerGroupOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mt-16">
          <button
            onClick={handleConfirm}
            className="w-full bg-[#9DACFF] text-white px-4 py-2 rounded-md hover:bg-[#828fdc]"
          >
            Confirm
          </button>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        message={modalMessage}
        type={modalType}
      />
    </div>
  );
};

export default ModalChangeGroup;
