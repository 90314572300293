import React, { useContext, useEffect } from "react";
import {
   BrowserRouter as Router,
   Route,
   Routes,
   Navigate,
} from "react-router-dom";
import Layout from "./components/shared/Layout";
import PreParameters from "./components/pages/PreParameters/PreParameters";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import StrategyRecord from "./components/pages/StrategyRecord/StrategyRecord";
import ApprovalStatus from "./components/pages/ApprovalStatus/ApprovalStatus";
import OrderAlert from "./components/pages/OrderAlert/OrderAlert";
import CustomerList from "./components/pages/CustomerList/CustomerTable/CustomerList";
import EmployeeList from "./components/pages/EmployeeList/EmployeeTable/EmployeeList";
import AgentList from "./components/pages/AgentList/AgentTable/AgentList";
import AddCustomer from "./components/pages/CustomerList/CustomerProfile/AddCustomer";
import AddAgent from "./components/pages/AgentList/AgentProfile/AddAgent";
import AddEmployee from "./components/pages/EmployeeList/EmployeeProfile/AddEmployee";
import CustomerProfile from "./components/pages/CustomerList/CustomerProfile/CustomerProfile";
import EmployeeProfile from "./components/pages/EmployeeList/EmployeeProfile/EmployeeProfile";
import RegId from "./components/pages/AgentPages/RegIdList/RegID";
import DashboardAgent from "./components/pages/AgentPages/DashboardAgent";
import AgentProfile from "./components/pages/AgentList/AgentProfile/AgentProfile";
import ProtectedRoute from "./components/ProtectedRoutes";
import { AccountType } from "./static/enums/accountTypeEmum";
import AdminLoginPage from "./pages/AdminLoginPage";
import ApproveCustomer from "./components/pages/ApprovalStatus/ApproveCustomer";
import CustomerOwnProfile from "./components/pages/CustomerOwn/CustomerOwnProfile";
import NotFound from "./components/additional/NotFound";
import PrivacyPolice from "./pages/PrivacyPolice";
import EntryOrderList from "./components/pages/EntryOrder/EntryOrderList";
import ExitOrderList from "./components/pages/ExitOrder/ExitOrderList";
import AuthRoutes from "./components/AuthRoutes";
import CompanyLogo from "./components/CompanyLogo";
import TermsOfServes from "./pages/TermsOfServes";
import { closeWebSocket, connectWebSocket } from "./api/webSocket";
import Cookies from "js-cookie";
import { ErrorContext } from "./components/ErrorProvider";
import { getAccount } from "./api/accountApi";
import Customers from "./components/pages/AgentPages/Customers/Customers";
import SettingsList from "./components/pages/WebHook/SettingsList";

const App = () => {
   const { setHasErrorType1, setHasErrorType2 } = useContext(ErrorContext);

   useEffect(() => {
      const fetchAccount = async () => {
         const { account } = await getAccount();
         if (account) {
            const type = account.data.role.accountType;

            if (type === AccountType.Admin || type === AccountType.Employee) {
               const token = Cookies.get("accessToken");
               connectWebSocket(token, setHasErrorType1, setHasErrorType2);
            }
         }
      };

      fetchAccount();

      return () => {
         closeWebSocket();
      };
   }, []);

   return (
      <Router>
         <Routes>
            <Route path="/" element={<Navigate to="/auth/login" />} />

            <Route
               path="/"
               element={
                  <ProtectedRoute
                     requiredAccountType={[
                        AccountType.Admin,
                        AccountType.Employee,
                        AccountType.Agent,
                     ]}
                  >
                     <Layout />
                  </ProtectedRoute>
               }
            >
               <Route
                  path="employee/parameters/preset"
                  element={
                     <ProtectedRoute
                        requiredAccountType={[AccountType.Employee]}
                     >
                        <PreParameters />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path="employee/strategies"
                  element={
                     <ProtectedRoute
                        requiredAccountType={[AccountType.Employee]}
                     >
                        <StrategyRecord />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path="employee/orders"
                  element={
                     <ProtectedRoute
                        requiredAccountType={[AccountType.Employee]}
                     >
                        <OrderAlert />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path="employee/dashboard"
                  element={
                     <ProtectedRoute
                        requiredAccountType={[AccountType.Employee]}
                     >
                        <Dashboard />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path="employee/add"
                  element={
                     <ProtectedRoute requiredAccountType={[AccountType.Admin]}>
                        <AddEmployee />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path="employee/:employeeId"
                  element={
                     <ProtectedRoute
                        requiredAccountType={[
                           AccountType.Admin,
                           AccountType.Employee,
                        ]}
                     >
                        <EmployeeProfile />
                     </ProtectedRoute>
                  }
               />

               <Route
                  path="customer/add"
                  element={
                     <ProtectedRoute
                        requiredAccountType={[
                           AccountType.Admin,
                           AccountType.Employee,
                        ]}
                     >
                        <AddCustomer />
                     </ProtectedRoute>
                  }
               />

               <Route
                  path="customers/approval/:customerId"
                  element={
                     <ProtectedRoute
                        requiredAccountType={[
                           AccountType.Admin,
                           AccountType.Employee,
                        ]}
                     >
                        <ApproveCustomer />
                     </ProtectedRoute>
                  }
               />

               <Route
                  path="admin/parameters/preset"
                  element={
                     <ProtectedRoute requiredAccountType={[AccountType.Admin]}>
                        <PreParameters />
                     </ProtectedRoute>
                  }
               />

               <Route
                  path="strategy/:strategyId"
                  element={
                     <ProtectedRoute
                        requiredAccountType={[
                           AccountType.Admin,
                           AccountType.Employee,
                        ]}
                     >
                        <PreParameters />
                     </ProtectedRoute>
                  }
               />

               <Route
                  path="admin/strategies"
                  element={
                     <ProtectedRoute requiredAccountType={[AccountType.Admin]}>
                        <StrategyRecord />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path="admin/orders"
                  element={
                     <ProtectedRoute requiredAccountType={[AccountType.Admin]}>
                        <OrderAlert />
                     </ProtectedRoute>
                  }
               />

               <Route
                  path="agent/add"
                  element={
                     <ProtectedRoute requiredAccountType={[AccountType.Admin]}>
                        <AddAgent />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path="admin/dashboard"
                  element={
                     <ProtectedRoute requiredAccountType={[AccountType.Admin]}>
                        <Dashboard />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path="customers/approval"
                  element={
                     <ProtectedRoute
                        requiredAccountType={[
                           AccountType.Admin,
                           AccountType.Employee,
                        ]}
                     >
                        <ApprovalStatus />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path="agent/:agentId"
                  element={
                     <ProtectedRoute
                        requiredAccountType={[
                           AccountType.Admin,
                           AccountType.Employee,
                        ]}
                     >
                        <AgentProfile />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path="agents"
                  element={
                     <ProtectedRoute
                        requiredAccountType={[
                           AccountType.Admin,
                           AccountType.Employee,
                        ]}
                     >
                        <AgentList />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path="customers"
                  element={
                     <ProtectedRoute
                        requiredAccountType={[
                           AccountType.Admin,
                           AccountType.Employee,
                        ]}
                     >
                        <CustomerList />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path="employees"
                  element={
                     <ProtectedRoute
                        requiredAccountType={[
                           AccountType.Admin,
                           AccountType.Employee,
                        ]}
                     >
                        <EmployeeList />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path="activity/entry"
                  element={
                     <ProtectedRoute
                        requiredAccountType={[
                           AccountType.Admin,
                           AccountType.Employee,
                        ]}
                     >
                        <EntryOrderList />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path="activity/exit"
                  element={
                     <ProtectedRoute
                        requiredAccountType={[
                           AccountType.Admin,
                           AccountType.Employee,
                        ]}
                     >
                        <ExitOrderList />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path="activity/account"
                  element={
                     <ProtectedRoute
                        requiredAccountType={[
                           AccountType.Admin,
                           AccountType.Employee,
                        ]}
                     ></ProtectedRoute>
                  }
               />
               <Route
                  path="activity/webhook"
                  element={
                     <ProtectedRoute
                        requiredAccountType={[
                           AccountType.Admin,
                           AccountType.Employee,
                        ]}
                     >
                        <SettingsList />
                     </ProtectedRoute>
                  }
               />
            </Route>

            <Route
               path="customer/:customerId"
               element={
                  <ProtectedRoute
                     requiredAccountType={[
                        AccountType.Admin,
                        AccountType.Employee,
                     ]}
                  >
                     <CustomerProfile />
                  </ProtectedRoute>
               }
            />

            <Route path="*" element={<NotFound />} />

            {/* Customer */}
            <Route
               path="customer/dashboard"
               element={
                  <ProtectedRoute requiredAccountType={[AccountType.Customer]}>
                     <CustomerOwnProfile />
                  </ProtectedRoute>
               }
            />

            {/* Agent */}
            <Route
               path="/"
               element={
                  <ProtectedRoute
                     requiredAccountType={[
                        AccountType.Admin,
                        AccountType.Employee,
                        AccountType.Agent,
                     ]}
                  >
                     <Layout />
                  </ProtectedRoute>
               }
            >
               <Route
                  path="agent/dashboard"
                  element={
                     <ProtectedRoute requiredAccountType={[AccountType.Agent]}>
                        <DashboardAgent />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path="agent/registrationids"
                  element={
                     <ProtectedRoute requiredAccountType={[AccountType.Agent]}>
                        <RegId />
                     </ProtectedRoute>
                  }
               />
               <Route
                  path="agent/customers"
                  element={
                     <ProtectedRoute requiredAccountType={[AccountType.Agent]}>
                        <Customers />
                     </ProtectedRoute>
                  }
               />
            </Route>

            {/* Auth */}

            <Route path="/auth" element={<CompanyLogo />}>
               <Route path="*" element={<AuthRoutes />} />
            </Route>

            <Route path="/admin/login" element={<AdminLoginPage />}></Route>
            <Route path="/privacy" element={<PrivacyPolice />} />
            <Route path="/terms" element={<TermsOfServes />} />
         </Routes>
      </Router>
   );
};

export default App;
