import React, { useState, useEffect, useContext } from 'react';
import PaginationControls from '../../shared/PaginationControls';
import ItemsPerPageDropdown from '../../shared/ItemsPerPageDropdown';
import DropdownSort from '../../shared/DropDownSort';
import Cookies from 'js-cookie';
import { getAllSymbols } from '../../../api/orderApi';
import { getExitData, getStatisticsExit, uploadExit } from '../../../api/exitApi';
import usePageTitle from '../../../hooks/usePageTitle';
import { useSearchParams } from 'react-router-dom';
import LoadingSkeletonExit from './LoadingSkeletonExit';
import ExitOrderHeader from './ExitOrderHeader';
import ExitOrderRow from './ExitOrderRow';
import { ErrorContext } from '../../ErrorProvider';
import { ExitType } from '../../../static/enums/exitTypeEnum';
import Modal from '../../shared/Modal';
import { base64ToBytes } from '../../../utils/Convertation';
import { getAllTransactionIds } from '../../../api/orderApi';


const ExitOrderList = ({ accountType }) => {
  usePageTitle('Exit Audit');

  const { setHasErrorType2 } = useContext(ErrorContext);
  setHasErrorType2(false);

  const [exitOrders, setExitOrders] = useState([]);
  const [symbolOptions, setSymbolOptions] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedCurrent, setSelectedCurrent] = useState({ value: 'current', label: 'Current' })

  const [selectedExitType, setSelectedExitType] = useState(null);
  const [statistic, setStatistic] = useState({
    error: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("success");

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [searchParams, setSearchParams] = useSearchParams({
    pageIndex: 1,
    month: '',
    current: 'current'
});
  const exitTypeOption = [
    { value: 0, label: 'All' },
    { value: ExitType['By Admin'], label: 'Manual (Admin)' },
    { value: ExitType.Customer, label: 'Manual (Customer)' },
  ];
  //{value: 'last', label: 'Last'},
  const defaultCurrentTotalOptions=[{value: 'current', label:'Today'},{ value: 'all', label: 'All' }]
  const [currentTotalOptions, setCurrentTotalOptions]=useState(defaultCurrentTotalOptions)
  

  useEffect(() => {
    const pageIndex = parseInt(searchParams.get('pageIndex'), 10) || 1;
    const symbol = searchParams.get('symbol');
    const current = searchParams.get('current') || 'current';
    const month = searchParams.get('month');
    const exitType = searchParams.get('exitType');

    setCurrentPage(pageIndex);

    if (symbolOptions.length === 0) {
      return;
    }

    if (symbol) {
      const foundSymbol = symbolOptions.find(
        (option) => option.value === symbol
      );
      setSelectedSymbol(
        foundSymbol
          ? foundSymbol
          : { value: foundSymbol.value, label: foundSymbol.label }
      );
    } else {
      setSelectedSymbol('');
    }

    if (!searchParams.has('current')) {
      setSearchParams((prevParams) => ({
        ...Object.fromEntries(prevParams),
        current: 'current',
      }));
    }
  
    const foundCurrent = currentTotalOptions.find((option) => option.value === current);
    setSelectedCurrent(
      foundCurrent || { value: 'current', label: 'Today' }
    );

    if (month) {
      const foundMonth = monthOptions.find((option) => option.value === month);
      setSelectedMonth(
        foundMonth
          ? foundMonth
          : { value: foundMonth.value, label: foundMonth.label }
      );
      setSelectedCurrent('')
    } else {
      setSelectedMonth('');
    }

    if (exitType) {
      const foundExitType = exitTypeOption.find(
        (option) => option.value === Number(exitType)
      );
      setSelectedExitType(
        foundExitType
          ? foundExitType
          : { value: foundExitType.value, label: foundExitType.label }
      );
    } else {
      setSelectedExitType('');
    }
  }, [searchParams, symbolOptions, currentTotalOptions]);

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get('accessToken');
      setIsLoading(true);
      try {
        const filters = [
        
        ];
        if (
          selectedCurrent.value &&
          !defaultCurrentTotalOptions.some((option) => option.value === selectedCurrent.value)
        ) {
          filters.push({
            name: 'transactionId',
            value: selectedCurrent.value,
          });
        }
        if (selectedMonth?.value && selectedMonth.value !== 'all') {
          filters.push({
            name: 'datetimeInserted',
            value: getMonthYearString(selectedMonth.value),
          });
        }

        if (selectedSymbol) {
          filters.push({
            name: 'strategy.symbol',
            value: selectedSymbol.value === 'all' ? '' : selectedSymbol.value,
          });
        }
        if (selectedExitType && selectedExitType.value !== 0) {
          filters.push({
            name: 'exitType',
            value: selectedExitType.value,
          });
        }
       
        const today = new Date();
        const toISOWithOffset = (date, offsetHours) => {
          const localDate = new Date(date.getTime() + offsetHours * 60 * 60 * 1000);
          const year = localDate.getFullYear();
          const month = String(localDate.getMonth() + 1).padStart(2, '0');
          const day = String(localDate.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
        };
        
        const offsetHours = 8;
        let startDate = null;
        let endDate = toISOWithOffset(today, offsetHours);
        
        if (selectedCurrent?.value === 'last') {
          const yesterday = new Date(today);
          yesterday.setDate(today.getDate() - 1);
          endDate = toISOWithOffset(yesterday, offsetHours);
        } else if (selectedCurrent?.value === 'current') {
          startDate = endDate;
        }
        

        const data = await getExitData(
          token,
          currentPage - 1,
          itemsPerPage,
          filters,
          endDate,
          startDate
        );

        if (data) {
          setExitOrders(data.data);
          setTotalItems(data.paging.totalCount);
          console.log(data);
        } else {
          console.error('Unexpected data format:', data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    itemsPerPage,
    currentPage,
    selectedMonth,
    selectedSymbol,
    selectedExitType,
    selectedCurrent
  ]);

  useEffect(() => {
    const fetchSymbols = async () => {
      const token = Cookies.get('accessToken');
      try {
        const result = await getAllSymbols(token);
        if (result && result.successful) {
          const symbols = result.data.symbols;
          setSymbolOptions([
            { value: 'all', label: 'All Symbols' },
            ...symbols.map((name) => ({ value: name, label: name })),
          ]);
        } else {
          console.error('Error fetching symbols:', result.error.message);
        }
      } catch (error) {
        console.error('Error fetching symbols:', error);
      }
    };

    fetchSymbols();
  }, []);

  useEffect(() => {
    const fetchTransactionIds = async () => {
      const token = Cookies.get('accessToken');
      try {
        const result = await getAllTransactionIds(token);
        if (result && result.successful) {
          const transactionIds = result.data.transaction_ids;
  
          setCurrentTotalOptions((prevOptions) => [
            ...prevOptions,
            ...transactionIds.map((name) => ({ value: name, label: name })),
          ]);
        } else {
          console.error('Error fetching transactionIds:', result.error.message);
        }
      } catch (error) {
        console.error('Error fetching transactionIds:', error);
      }
    };
  
    fetchTransactionIds();
  }, []);

  useEffect(() => {
    const fetchStatistics = async () => {
      const token = Cookies.get('accessToken');
      try {
        const result = await getStatisticsExit(token);
        if (result && result.successful) {
          setStatistic({ error: result.data.error });
        } else {
          console.error('Error fetching symbols:', result.error.message);
        }
      } catch (error) {
        console.error('Error fetching symbols:', error);
      }
    };

    fetchStatistics();
  }, []);

  const getMonthYearString = (monthYear) => {
    const [month, year] = monthYear.split(/(\d{4})/).filter(Boolean);
    const monthIndex = new Date(Date.parse(month + ' 1, 2020')).getMonth() + 1; // Преобразуем сокращенное название месяца в индекс и добавляем 1
    return `${year}-${monthIndex.toString().padStart(2, '0')}`; // Форматируем как YYYY-MM
  };

  const generateMonthsForRange = () => {
    const minDate = new Date(2024, 4, 1);
    const maxDate = new Date();

    const startYear = minDate.getFullYear();
    const endYear = maxDate.getFullYear();

    let allMonths = [{ value: 'all', label: 'All month' }];

    for (let year = endYear; year >= startYear; year--) {
      for (let month = 11; month >= 0; month--) {
        const date = new Date(year, month);

        if (year === 2024 && month < 4) continue;

        if (year === endYear && month > maxDate.getMonth()) continue;

        allMonths.push({
          value: `${date.toLocaleString('en-GB', { month: 'short' })}${year}`,
          label: `${date.toLocaleString('en-GB', { month: 'short' })} ${year}`,
        });
      }
    }

    return allMonths;
  };
  const monthOptions = generateMonthsForRange();

  const currentData = exitOrders;

  const hasRecords = currentData?.length > 0;

  const handleRowSelect = (index) => {
    const pageIndex = (currentPage - 1) * itemsPerPage + index;
    if (selectedRows.includes(pageIndex)) {
      setSelectedRows(selectedRows.filter((i) => i !== pageIndex));
    } else {
      setSelectedRows([...selectedRows, pageIndex]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAll(false);
    } else {
      const allIndexes = currentData.map(
        (_, index) => (currentPage - 1) * itemsPerPage + index
      );
      setSelectedRows(allIndexes);
      setSelectAll(true);
    }
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setSearchParams({
      pageIndex: newPage,
      symbol: selectedSymbol?.value || '',
      month: selectedMonth?.value || '',
      exitType: selectedExitType?.value || '',
      current: selectedCurrent?.value || ''
    });
    setSelectAll(false);
  };

  const handleItemsPerPageChange = (event) => {
    const newPageSize = Number(event.target.value);
    setItemsPerPage(newPageSize);
    setCurrentPage(1);
  };

  const handleSymbolChange = (selectedOption) => {
    setSelectedSymbol(selectedOption);
    setSearchParams({
      pageIndex: 1,
      symbol: selectedOption?.value || '',
      month: selectedMonth?.value || '',
      exitType: selectedExitType?.value || '',
      current: selectedCurrent?.value || ''
    });
    setCurrentPage(1);
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
    setSearchParams({
      pageIndex: 1,
      symbol: selectedSymbol?.value || '',
      month: selectedOption?.value || '',
      exitType: selectedExitType?.value || '',
      current: 'all'
    });
    setSelectedCurrent({ value: 'all', label: 'All' })
    setCurrentPage(1);
  };

  const handleCurrentChange = (selectedOption) => {
    setSelectedCurrent(selectedOption);
    setSelectedMonth('')
    setSearchParams({
      pageIndex: 1,
      symbol: selectedSymbol?.value || '',
      month: '',
      exitType: selectedExitType?.value || '',
      current: selectedOption?.value || ''
    });

    setCurrentPage(1);
  };

  const handleExitTypeChange = (selectedOption) => {
    setSelectedExitType(selectedOption);
    setSearchParams({
      pageIndex: 1,
      symbol: selectedSymbol?.value || '',
      month: selectedMonth?.value || '',
      exitType: selectedOption?.value || '',
      current: selectedCurrent?.value || ''
    });
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setSelectedSymbol(null);
    setSelectedMonth(null);
    setSelectedExitType(null);
    setSelectedCurrent({ value: 'current', label: 'Current' })
    setSearchParams({});
    setCurrentPage(1);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };


  const handleExport = async () => {
    try {
      const token = Cookies.get('accessToken');
      if (!token) {
        console.error('No token found');
        return;
      }
      const result = await uploadExit(token);
      if (result.successful) {
        const fileBase64String = result.data?.fileBase64String;
  
        if (fileBase64String) {
          const byteArray = base64ToBytes(fileBase64String);
          
          if (byteArray) {
            const blob = new Blob([byteArray], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'exit.xlsx'; 
            a.click();
            URL.revokeObjectURL(url); 
            setIsModalOpen(true)
            setModalType('success')
            setModalMessage('File is download successfully')
          } else {
            setIsModalOpen(true)
            setModalType('error')
            setModalMessage('File is not download')
          }
        } else {
          setIsModalOpen(true)
          setModalType('error')
          setModalMessage('File is not download')
        }
      } else {
        setIsModalOpen(true)
        setModalType('error')
        setModalMessage('File is not download')
      }
    } catch (error) {
      setIsModalOpen(true)
            setModalType('error')
            setModalMessage('File is not download')
    }
  };

  return (
    <div className="bg-grey-light md:p-16 p-4 min-h-screen">
      <h1 className="text-purple-circle font-bold md:text-3xl text-base text-center md:text-left md:ml-20 md:mb-10 mb-5">
        Exit Order Audit
      </h1>
      <div className="flex justify-end items-center md:space-x-5 space-x-1 py-2 px-5">
        <button onClick={handleExport}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
            />
          </svg>
        </button>
        <span className="font-semibold md:text-sm text-[6px]">
          Total Exit({totalItems})
        </span>
        <span className="font-semibold md:text-sm text-[6px]">
          Error case({statistic.error})
        </span>

        <div className="xl:w-1/6">
          <DropdownSort
            options={symbolOptions}
            selected={selectedSymbol}
            onChange={handleSymbolChange}
            title={'Symbol'}
          />
        </div>
        <div className="xl:w-1/6">
          <DropdownSort
            options={currentTotalOptions}
            selected={selectedCurrent}
            onChange={handleCurrentChange}
            title={'All'}
          />
        </div>
        <div className="xl:w-1/6">
          <DropdownSort
            options={monthOptions}
            selected={selectedMonth}
            onChange={handleMonthChange}
            title={'Month'}
          />
        </div>
        <div className="xl:w-1/6">
          <DropdownSort
            options={exitTypeOption}
            selected={selectedExitType}
            onChange={handleExitTypeChange}
            title={'Exit Type'}
          />
        </div>
        <button onClick={handleResetFilters}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="md:size-6 size-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
            />
          </svg>
        </button>
      </div>
      <div className="flex flex-col justify-center items-center gap-8">
        <div className="w-full items-start bg-[#ffffff] md:py-10 py-2 rounded-lg md:px-12 px-1.5">
          {/* {selectedMonth && (
						<div className="text-end md:text-base text-[8px] text-gray-700 font-semibold mb-4">
							{selectedMonth.label} ({monthEntriesCount})
						</div>
					)} */}
          <ExitOrderHeader
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
          />
          {isLoading ? (
            <LoadingSkeletonExit itemsPerPage={itemsPerPage} />
          ) : hasRecords ? (
            currentData.map((item, index) => (
              <ExitOrderRow
                key={index}
                item={item}
                index={(currentPage - 1) * itemsPerPage + index}
                isSelected={selectedRows.includes(
                  (currentPage - 1) * itemsPerPage + index
                )}
                handleRowSelect={handleRowSelect}
                accountType={accountType}
              />
            ))
          ) : (
            <div className="text-center pt-10">No records available</div>
          )}
          <div className="flex justify-end space-x-5">
            <ItemsPerPageDropdown
              itemsPerPage={itemsPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              totalItems={totalItems}
            />
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        message={modalMessage}
        type={modalType}
      />
    </div>
  );
};

export default ExitOrderList;
