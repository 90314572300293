import api from ".";

export const getReceiveOnlyData = async (token, pageIndex, pageSize) => {
   try {
      if (!token) {
         return { account: null, statusCode: 401 };
      }
      const response = await api.get("/api/receiveonly/all", {
         headers: {
            Authorization: `Bearer ${token}`,
         },
         params: {
            pageIndex,
            pageSize,
         },
      });

      if (response.data.successful) {
         return response.data.data;
      } else {
         return { data: null, error: response.data.error.message };
      }
   } catch (error) {
      return { data: null, error: error.message || "Request failed" };
   }
};

export const changeReceiveOnly = async (receiveonlyId, token, isActive) => {
	try {
		if (!token) {
			return { account: null, statusCode: 401 };
		}
		const response = await api.patch(
			`/api/receiveonly/${receiveonlyId}`,
			{ isActive }, 
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		if (response.data.successful) {
			return response.data.data;
		} else {
			throw new Error(
				response.data.error.message || "Unknown error occurred"
			);
		}
	} catch (error) {
		console.error(error.response ? error.response.data : error.message);
		throw error;
	}
};

