import React from "react";
import { useNavigate } from "react-router-dom";
import Status from "../../../../static/enums/statusEnum";

const AgentTableRow = ({ item, index, isSelected, handleRowSelect }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/agent/${item.id}`);
  };


  return (
    <div
      className={`flex items-center md:px-6 md:py-4 px-1 py-2 border md:my-4 my-2 border-grey-light rounded-full ${
        isSelected ? "bg-gray-100" : ""
      }`}
    >
      <span className="tr_style w-1/7 text-gray-600 font-semibold">{index + 1}</span>
      <span className="tr_style w-1/7">
      {item.account ? (
          <>
            {item.account.firstName} <br /> {item.account.lastName}
          </>
        ) : (
          '-'
        )}
      </span>
      <span className="tr_style w-1/7">{item.account.country?.name || "-"}</span>
      <span className="tr_style w-1/7 flex items-center">
        <span className="flex items-center w-2/3">
          <span
            className={`md:py-1.5 py-0.5 md:text-xs text-[5px] self-center font-semibold rounded-full flex-grow text-center ${
              Number(item.account.status) === 3
                ? "bg-[#5DF888] text-black"
                : "bg-[#FF4949] text-white"
            }`}
          >
            {Status.find(
              (status) => status.value === Number(item.account.status)
            )?.label || "Suspended"}
          </span>
        </span>
      </span>

      <span className="tr_style w-1/7">{item.totalCustomers || "0"}</span>
      <span className="tr_style w-1/7">
      {item.account.datetimeInserted
                  ? (() => {
                        const [datePart] = item.account.datetimeInserted.split('T');
                        const [year, month, day] = datePart.split('-');

                        const formattedDate = `${day}/${month}/${year}`;

                        return <>{formattedDate}</>;
                    })()
                  : '-'}
      </span>

      <button
        className="tr_style w-1/7 text-purple-600 hover:text-purple-800 text-center flex justify-center"
        onClick={handleClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="md:w-5 w-2.5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
          />
        </svg>
      </button>
    </div>
  );
};

export default AgentTableRow;
