import api from './index';

export const getOrdersData = async (
	token,
	pageIndex,
	pageSize,
	filters = []
) => {
	try {
		const response = await api.post(
			'/api/order/search',
			{
				filter: filters,
				pageSize: pageSize,
				pageIndex: pageIndex,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		if (response.data.successful) {
			return response.data.data;
		} else {
			console.error('Error fetching data:', response.data.error.message);
			return null;
		}
	} catch (error) {
		console.error('Request failed:', error);
		return null;
	}
};

export const updateOrder = async (token, orderId) => {
	try {
		const response = await api.patch(
			`/api/order/${orderId}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		if (response.data.successful) {
			return response.data;
		} else {
			return response.data.error.message;
		}
	} catch (error) {
		console.error('Request failed:', error);
		return null;
	}
};

export const getAllSymbols = async (token) => {
	try {
		const response = await api.get('/api/order/symbol/all', {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		
		if (response.data.successful) {
			return response.data;
		} else {
			console.error('Error:', response.data.error.message);
			return response.data.error.message;
		}
	} catch (error) {
		console.error('Request failed:', error);
		return error;
	}
};

export const getAllTransactionIds = async (token) => {
	try {
		const response = await api.get('/api/order/transactionids/all', {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		
		if (response.data.successful) {
			return response.data;
		} else {
			console.error('Error:', response.data.error.message);
			return response.data.error.message;
		}
	} catch (error) {
		console.error('Request failed:', error);
		return error;
	}
};

export const getStatistics= async (token) => {
	try {
		const response = await api.get('/api/order/statistics', {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		
		if (response.data.successful) {
			return response.data;
		} else {
			console.error('Error:', response.data.error.message);
			return response.data.error.message;
		}
	} catch (error) {
		console.error('Request failed:', error);
		return error;
	}
};

